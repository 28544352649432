import React, { useState } from 'react';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';

import AppContext from 'shared/contexts/AppContext';

import GlobalStyles from './globalStyles';
import theme from './theme';
import HomeTemplate from 'shared/components/HomeTemplate';
import EventsTemplate from 'shared/components/EventsTemplate';
import ProjectsTemplate from 'shared/components/ProjectsTemplate';

import Home from './screens/Home';
import EventContainer from './screens/Events/EventContainer';

import MoneroCoreContributions from './screens/Projects/MoneroCoreContributions';
import TelegramBots from './screens/Projects/TelegramBots';
import Publications from './screens/Projects/Publications';

import NotFound from './screens/NotFound';

const routerHistory = createHistory(window);

const App = () => {
  const [isOpened, setIsOpened] = useState(false);

  const appState = {
    isOpened,
    setIsOpened,
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={appState}>
        <GlobalStyles />

        <LocationProvider history={routerHistory}>
          <Router className="routerwrap">
            <HomeTemplate path="/">
              <Home path="/" />
            </HomeTemplate>
            <EventsTemplate path="/event">
              <EventContainer path=":eventId" />
            </EventsTemplate>
            <ProjectsTemplate path="/projects">
              <MoneroCoreContributions path="/monero" />
              <TelegramBots path="/tgbots" />
              <Publications path="/publications" />
            </ProjectsTemplate>
            <NotFound path="/404" />
          </Router>
        </LocationProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
