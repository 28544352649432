import React from 'react';
import { Helmet } from 'react-helmet';

import Bots from './components/Bots';
import ProjectsContext from 'shared/contexts/ProjectsContext';

const TelegramBotsContainer = ({ navigate, path }) => {
  return (
    <ProjectsContext.Provider>
      <Helmet>
        <title>Telegram Bots — Exantech</title>
        <meta name="description" content="Exantech" />
      </Helmet>
      <Bots />
    </ProjectsContext.Provider>
  );
};

export default TelegramBotsContainer;
