import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import AboutNumbers from 'shared/components/AboutNumbers';
import Persons from 'shared/components/Persons';
import Schedule from 'shared/components/Schedule';
import Map from 'shared/components/Map';

import { ReactComponent as SvgDotsBig } from 'shared/assets/icon-dots-big.svg';

const MapSection = styled.section`
  position: relative;
  overflow: visible;
  margin: 0;
  z-index: 3;

  .map {
    position: relative;

    @media ${Device.tablet} {
      left: -200px;
      min-width: 2000px;
    }
  }
`;

const SubTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 10px 0;
  width: 100%;
  font-size: 22px;
  line-height: 24px;

  @media ${Device.tablet} {
    font-size: 30px;
    line-height: 48px;
    margin: 0 0 30px 0;
  }
`;
const Half = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 48%;
  }
`;
const Third = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 30%;
  }
`;
const BorderBox = styled.div`
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  border: 2px solid ${props => props.theme.color.green};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: relative;

  @media ${Device.tablet} {
    padding: 50px;
    font-size: 30px;
    line-height: 48px;
    margin: 0;
  }

  .dots {
    position: absolute;
    top: -30px;
    right: 0;

    @media ${Device.tablet} {
      top: -130px;
      right: -30px;
    }
  }
`;
const PartnersLogo = styled.img`
  height: 20px;
  max-width: 100%;
  margin: 0 15px 0 15px;

  @media ${Device.tablet} {
    margin: 0 40px 0 40px;
    height: 40px;
  }
`;
const Partners = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px 0 0 0;

  @media ${Device.tablet} {
    margin: 0;
  }
`;
const DotsBig = styled(SvgDotsBig)`
  width: 120px;
  height: 120px;
  pointer-events: none;
  color: ${props => props.theme.color.green};
  fill: ${props => props.theme.color.green};

  @media ${Device.tablet} {
    width: 250px;
    height: 250px;
  }
`;

const ExantechBusinessCyprus = () => {
  const dataAbout = [
    {
      num: '6',
      text: 'speakers',
    },
    {
      num: '6',
      text: 'hours',
    },
    {
      num: '50',
      text: 'participants',
    },
  ];
  const speakers = [
    {
      name: 'Anatoliy Knyazev',
      text:
        'Executive director of the <mark>EXANTE</mark> brokerage company, Bitcoin Fund and XAI Fund co-founder.',
      img: '/assets/i/speakers/anatoliy-knyazev.png',
    },
    {
      name: 'Gregory Klumov',
      text:
        'Founder of <mark>STASIS</mark> – an infrastructural project on creating the world`s first digital currency with regular state audit.',
      img: '/assets/i/speakers/gregory-klumov.jpg',
    },
    {
      name: 'Yiannis Menelaou',
      text:
        'Executive Director at <mark>Lykke</mark>, Lecturer at the University of Nicosia, ICO Advisor',
      img: '/assets/i/speakers/yiannis-menelaou.jpg',
    },
    {
      name: 'Evgeniy Vlasov',
      text:
        'Co-founder and CEO of <mark>Comino</mark> – a start-up producing professional devices for mining Ethereum and other cryptocurrencies.',
      img: '/assets/i/speakers/evgeniy-vlasov.png',
    },
    {
      name: 'Konstantin Orlov',
      text:
        'Leading expert in international corporate and finance transactions.',
      img: '/assets/i/speakers/konstantin-orlov.jpg',
    },
    {
      name: 'Theodosis Mourouzis',
      text:
        'Program Director of <mark>MSc</mark> in Business Intelligence & Data Analytics, Research Fellow at <mark>UCL CBT</mark>',
      img: '/assets/i/speakers/theodosis-mourouzis.jpg',
    },
  ];
  const schedule = [
    {
      time: '09:00 – 09:30',
      name: 'Registration',
    },
    {
      time: '09:30 – 10:10',
      name:
        'Yiannis Menelaou, Executive Director at Lykke, Lecturer at the University of Nicosia',
      text: 'Blockchain in Business and How the Crypto-Exchanges React.',
    },
    {
      time: '10:10 – 10:50',
      name: 'Theodosis Mourouzis, Program Director of MSc',
      text: 'Blockchain Demystified.',
    },
    {
      time: '10:50 – 11:30',
      name: 'Gregory Klumov, CEO STASIS',
      text:
        'Investment opportunities: research and evaluation. The most popular funds and trust managers in Cyprus and all over the world. Minimum size of initial investment. Investment safety and security.',
    },
    {
      time: '11:30 – 12:00',
      name: 'Coffee break',
    },
    {
      time: '12:00 – 12:40',
      name: 'Evgeny Vlasov, CEO and co-founder of Comino',
      text:
        'Mining as long term investment: сommon practice, world review and hedging of resource.',
    },
    {
      time: '12:40 – 13:20',
      name: 'Konstantin Orlov, lawyer',
      text:
        'Legal and practical aspects of ICOs and investing in cryptocurrencies. Legal aspects of using blockchain technology.',
    },
    {
      time: '13:20 – 13:50',
      name: 'Anatoliy Knyazev, Executive director of EXANTE',
      text:
        'Q&A session: Bitcoin, Bitcoin Cash, Litecoin, Ethereal, Ripple, and other popular alt coins. Professional cryptotrading: exchanges and traders. Pros and cons of cryptocurrency wallets.',
    },
    {
      time: '13:50 – 15:00',
      name: 'Networking',
    },
  ];

  return (
    <Fragment>
      <Section>
        <SubTitle>What is it about?</SubTitle>
        <Half>
          <p>
            People are getting more and more involved into{' '}
            <mark>cryptocurrencies and blockchain technologies</mark> nowadays.
            However, only a few of them are aware of the initial purpose of
            different tokens and of the benefits they can bring to their
            business.
          </p>
          <AboutNumbers data={dataAbout}>{dataAbout}</AboutNumbers>
        </Half>
        <Half>
          <p>
            During the meeting organized for a limited number of participants{' '}
            <mark>
              we will guide you through the innovative blockchain technology
            </mark>
            , highlighting the advantages it can offer to your business.
          </p>
          <p>
            The leading industry experts will make an overview of the most
            significant blockchain projects. They will also talk on the
            international cases of its legal regulation and the prospects for
            investing in cryptocurrencies in Cyprus and abroad: both by buying
            cryptocurrencies directly and investing in mining facilities.
          </p>
        </Half>
      </Section>
      <Section>
        <SubTitle>Who needs this meeting?</SubTitle>
        <Third>
          <h4>Private Investors</h4>
          <p>
            Evaluate the prospects of specific cryptos, start-ups, and operating
            businesses. Learn to distinguish fraud schemes, to form an estimate
            opinion on someone's ICO, and to launch your own.
          </p>
        </Third>
        <Third>
          <h4>Business Owners</h4>
          <p>
            Assess the investment attractiveness of cryptocurrency industry:
            risks, liquidity, and reasons for growth.
          </p>
        </Third>
        <Third>
          <h4>Senior Managers</h4>
          <p>
            Learn to buy, sell and maintain payments in cryptocurrencies — all
            compliant to law.
          </p>
        </Third>
      </Section>
      <Section>
        <Half>
          <SubTitle>What will you learn?</SubTitle>
          <ul>
            <li>
              <p>
                Banking and stock market products{' '}
                <mark>based on cryptocurrencies</mark>: futures, hedge funds,
                etc.
              </p>
            </li>
            <li>
              <p>
                Reasons for investing in blockchain projects and the ways{' '}
                <mark>to evaluate</mark> their attractiveness.
              </p>
            </li>
            <li>
              <p>
                <mark>Legal</mark> aspects: basic terms and restrictions.
              </p>
            </li>
            <li>
              <p>
                The cases of cryptocurrency regulation{' '}
                <mark>all over the world.</mark>
              </p>
            </li>
          </ul>
        </Half>
        <Half>
          <BorderBox>
            <DotsBig className="dots" />
            An opportunity to get a firsthand advice from the leading industry
            experts and expand your professional network.
          </BorderBox>
        </Half>
      </Section>
      <Section>
        <SubTitle>Speakers</SubTitle>
        <Persons data={speakers}></Persons>
      </Section>
      <Section>
        <SubTitle>Schedule</SubTitle>
        <Schedule data={schedule}></Schedule>
      </Section>
      <Section>
        <SubTitle>Media partners</SubTitle>
        <Partners>
          <PartnersLogo src="/assets/i/partners/forklog.svg" alt="ForkLog" />
          <PartnersLogo src="/assets/i/partners/ihodl.svg" alt="iHodl" />
        </Partners>
      </Section>
      <MapSection>
        <Map
          lat={34.6710109}
          lng={33.0420809}
          title="09:00 — 15:00 Amathus Hotel 75 Amathounta Avenue, Limassol, Cyprus"
        />
      </MapSection>
    </Fragment>
  );
};

export default ExantechBusinessCyprus;
