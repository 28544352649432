import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import Device from 'device';

import AppContext from 'shared/contexts/AppContext';

import Topline from 'shared/components/Topline.js';
import Sidebar from 'shared/components/Sidebar.js';

const Main = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.width.container};
  margin: 0 auto;
  padding: 0 20px;

  @media ${Device.laptopM} {
    padding: 0;
  }
  @media ${Device.laptopL} {
  }
`;

const ContentWrapper = styled.div`
  padding: 80px 0 0 0;

  @media ${Device.tablet} {
    padding: 125px 0 0 260px;
    min-height: 100vh;
  }
`;
const Content = styled.main`
  padding: 0;
  position: relative;
  z-index: 2;

  @media ${Device.tablet} {
    padding: 60px 0 0 60px;
  }
`;

const Body = styled.div`
  background: transparent;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
`;

const EventsTemplate = ({ children }) => {
  const { isOpened, setIsOpened } = useContext(AppContext);

  const appState = {
    isOpened,
    setIsOpened,
  };

  return (
    <AppContext.Provider value={appState}>
      <Fragment>
        <Main>
          <Topline />
          <Body>
            <Container>
              <Sidebar />
              <ContentWrapper>
                <Content>{children}</Content>
              </ContentWrapper>
            </Container>
          </Body>
        </Main>
      </Fragment>
    </AppContext.Provider>
  );
};

export default EventsTemplate;
