import React, { useState } from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';

const Wrap = styled.div`
  position: relative;
`;
const Hidden = styled.div`
  opacity: 0;

  &.show {
    opacity: 1;
  }
`;
const TypeText = styled(Typist)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &.hide {
    opacity: 0;
  }
`;

const TypistFixHeight = ({ children, cls = '', ...props }) => {
  const [showText, setShowText] = useState(false);

  return (
    <Wrap className={cls} $ {...props} onClick={() => setShowText(true)}>
      <Hidden className={showText ? 'show' : null}>{children}</Hidden>
      <TypeText
        cursor={props.cursor}
        avgTypingDelay={props.avgTypingDelay}
        stdTypingDelay={props.stdTypingDelay}
        startDelay={props.startDelay}
        className={showText ? 'hide' : null}
      >
        {children}
      </TypeText>
    </Wrap>
  );
};

export default TypistFixHeight;
