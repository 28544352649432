import React, { Fragment, useState, useContext } from 'react';
import styled from 'styled-components';
import Device from 'device';

import HomeContext from 'shared/contexts/HomeContext';
import AppContext from 'shared/contexts/AppContext';

import Topline from 'shared/components/Topline.js';
import Sidebar from 'shared/components/Sidebar.js';
import Egg from 'shared/components/Egg.js';

const Main = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.theme.width.container};
  margin: 0 auto;
  padding: 0 10px;
  display: inline-block;

  @media ${Device.laptopM} {
    padding: 0;
    display: block;
  }
`;
const Body = styled.div`
  background: transparent;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  padding: 0;

  @media ${Device.tablet} {
    padding: 0 0 0 260px;
    min-height: 100vh;
  }
`;
const Content = styled.div`
  padding: 0;
  position: relative;
  z-index: 2;

  @media ${Device.tablet} {
    /*padding: 60px 0 0 60px;*/
  }
`;

const HomeTemplate = ({ children }) => {
  const [activeSection, setActiveSection] = useState('intro');
  const { isOpened, setIsOpened } = useContext(AppContext);
  const handleSetActive = to => {
    setIsOpened(isOpened === true ? false : false);
    setActiveSection(to);
  };

  const homeState = {
    activeSection,
    setActiveSection,
  };

  return (
    <HomeContext.Provider value={homeState}>
      <Fragment>
        <Main>
          <Topline />
          <Body className={'section-' + activeSection}>
            <Container>
              <Sidebar handleSet={handleSetActive} />
              <ContentWrapper>
                <Content>{children}</Content>
              </ContentWrapper>
            </Container>
          </Body>
        </Main>
        <Egg />
      </Fragment>
    </HomeContext.Provider>
  );
};

export default HomeTemplate;
