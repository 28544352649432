import React from 'react';
import styled from 'styled-components';
import Device from 'device';
import ReactHtmlParser from 'react-html-parser';

import { ReactComponent as SvgDots } from 'shared/assets/icon-dots.svg';

const PersonsWrap = styled.div``;
const Person = styled.section`
  display: block;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 25px 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    h3 {
      color: ${props => props.theme.color.green};
    }
    .photo-wrap {
      opacity: 1;
      visibility: visible;
      z-index: 9999;
    }
    .photo {
      transform: translateX(0);
    }
    .dots {
      transform: translateX(0);
    }
    p,
    mark {
      color: #fff;
    }
  }
`;
const Name = styled.h3`
  font-size: 18px;
  margin: 0 0 5px 0;
  line-height: 22px;
  color: #fff;
  transition: all 0.4s;

  @media ${Device.tablet} {
    font-size: 20px;
    line-height: 32px;
  }
`;
const Position = styled.p`
  font-size: 18px;
  margin: 0 0 5px 0;
  line-height: 20px;
  color: ${props => props.theme.color.line};
  position: relative;
  z-index: 3;
  transition: all 0.4s;
  mix-blend-mode: difference;

  @media ${Device.tablet} {
    width: 60%;
    font-size: 20px;
    line-height: 32px;
  }

  &:after {
    content: '/>';
    margin: 0 0 0 10px;
  }
`;
const PhotoWrap = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  height: 120px;
  margin-top: -60px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s;
  z-index: 2;

  @media ${Device.tablet} {
    height: 240px;
    margin-top: -120px;
  }
`;
const Photo = styled.img`
  width: 120px;
  height: 120px;
  transform: translateX(50px);
  transition: all 0.2s;

  @media ${Device.tablet} {
    width: 240px;
    height: 240px;
  }
`;
const Dots = styled(SvgDots)`
  fill: ${props => props.theme.color.green};
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: -50px;
  transition: all 0.2s 0.2s;
  transform: translateX(-50px);
  transform-origin: center;
  transform-box: fill-box;

  @media ${Device.tablet} {
    width: 162px;
    height: 162px;
    margin-top: -81px;
    left: -107px;
  }
`;

const Persons = ({ data = '', ...props }) => {
  return (
    <PersonsWrap>
      {data.map((person, index) => (
        <Person key={index}>
          <Name>{person.name}</Name>
          {person.text ? (
            <Position>{ReactHtmlParser(person.text)}</Position>
          ) : null}
          {person.img ? (
            <PhotoWrap className="photo-wrap">
              <Photo
                className="photo"
                src={person.img}
                alt={person.name ? person.name : 'Exan.tech person'}
              />
              <Dots className="dots" />
            </PhotoWrap>
          ) : null}
        </Person>
      ))}
    </PersonsWrap>
  );
};

export default Persons;
