import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Device from 'device';

import Code from 'shared/components/Code';
import Section from 'shared/components/Section';
import PixelImg from 'shared/components/PixelImg';
import ExternalLink from 'shared/components/ExternalLink';
import Glitch from 'shared/components/Glitch';
import TypistFixHeight from 'shared/components/TypistFixHeight';

import { ReactComponent as SvgLogoMonero } from 'shared/assets/logo-monero.svg';
import { ReactComponent as SvgLogoTor } from 'shared/assets/logo-tor.svg';

import { ReactComponent as SvgIconLamp } from 'shared/assets/monero-icons/icon-lamp.svg';
import { ReactComponent as SvgIconWallet } from 'shared/assets/monero-icons/icon-wallet.svg';
import { ReactComponent as SvgIconPrivacy } from 'shared/assets/monero-icons/icon-privacy.svg';
import { ReactComponent as SvgIconUntrace } from 'shared/assets/monero-icons/icon-untrace.svg';
import { ReactComponent as SvgIconConfidential } from 'shared/assets/monero-icons/icon-confidential.svg';
import { ReactComponent as SvgIconSecurity } from 'shared/assets/monero-icons/icon-security.svg';

const SvgIconCss = css`
  height: 40px;
  width: 40px;
  margin: 15px 0 0 0;

  @media ${Device.tablet} {
    height: 80px;
    width: 80px;
    margin: 25px 0 0 0;
  }
`;
const LogoMonero = styled(SvgLogoMonero)`
  ${SvgIconCss}
`;
const LogoMoneroTitle = styled(SvgLogoMonero)`
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;

  @media ${Device.tablet} {
    width: 62px;
    height: 62px;
  }
`;
const LogoTor = styled(SvgLogoTor)`
  ${SvgIconCss}
`;
const A = styled(ExternalLink)`
  color: #fff;
`;
const Header = styled.header`
  padding: 0 0 30px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 0 0 60px 0;
  }

  .pixel {
    position: absolute;
    top: -100px;
    left: 30%;
    opacity: 0.4;
    max-height: 400px;
    z-index: -1;

    @media ${Device.tablet} {
      top: -200px;
      left: 50%;
      max-height: 800px;
    }
  }
  .dots {
    position: absolute;
    top: 50%;
    fill: #fff;
    right: 0;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    opacity: 0.6;
    transition: all 0.4s;

    @media ${Device.tablet} {
      right: -300px;
      width: 250px;
      height: 250px;
      margin-top: -125px;
    }
  }
`;
const Title = styled.h1`
  margin: 0 0 20px 0;
  font-size: 28px;
  line-height: 40px;
  font-weight: normal;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 62px;
    line-height: 62px;
  }
`;
const TitleHeading2 = styled.h2`
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: normal;
  width: 100%;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 58px;
  }
`;
const SubTitle = styled.h3`
  font-weight: normal;
  margin: 15px 0 5px 0;
  width: 100%;
  font-size: 20px;
  line-height: normal;

  @media ${Device.tablet} {
    font-size: 30px;
    margin: 30px 0 10px 0;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &.align-items-center {
    align-items: center;
  }
  iframe {
    max-width: 100%;
  }
`;
const FullWidth = styled.div`
  width: 100%;
  min-width: 100%;
`;
const Half = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 45%;
    min-width: 45%;
  }
`;
const Pre = styled.code`
  width: 100%;
  padding: 7px;
  margin: 0 0 10px 0;
  font-size: 0.9em;
  border-radius: 3px;
  background: #191919;
  box-shadow: inset 0 -5px 10px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-family: Consolas, monaco, monospace;
`;

const features = [
  {
    title: 'Fresh Codebase',
    desc:
      'Written from the ground up to deliver genuine privacy and anonymity. Not just another Bitcoin clone.',
    img: <SvgIconLamp />,
  },
  {
    title: 'Stealth Addresses',
    desc:
      'Your wallet address never appears on the public blockchain. This means no one that knows your wallet address can see how rich you are or you spend.',
    img: <SvgIconWallet />,
  },
  {
    title: 'Always-On Privacy',
    desc:
      'All transactions are confidential, which means every Monero user’s activity enhances the privacy of other users.',
    img: <SvgIconPrivacy />,
  },
  {
    title: 'Untraceable',
    desc:
      'The source of funds for each transaction is concealed using established and proven ring signature cryptographic techniques. This is achieved without the need for a trusted setup, safeguarding against malicious currency fabrication.',
    img: <SvgIconUntrace />,
  },
  {
    title: 'Confidential Amounts',
    desc:
      'Transaction amounts are encrypted to prevent transactions from being traced by the amount sent.',
    img: <SvgIconConfidential />,
  },
  {
    title: 'High Security ED25519 Curve Encryption',
    desc:
      'Cutting edge Elliptic Curve Cryptography that protects your funds from theft.',
    img: <SvgIconSecurity />,
  },
];

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
`;
const FeaturesItem = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;

  @media ${Device.tablet} {
    max-width: 45%;
    min-width: 45%;
    margin: 0 0 40px 0;
  }

  p {
    line-height: 18px;
    font-size: 14px;
  }
`;
const FeaturesTitle = styled.h3`
  margin: 0 0 15px 0;
  font-size: 24px;
`;
const FeaturesDesc = styled.p`
  margin: 0;
`;
const FeaturesImg = styled.div`
  margin: 0 15px 0 0;
  svg {
    width: 40px;
    height: 40px;
    margin: 0 0 15px 0;
    fill: #fff;

    @media ${Device.tablet} {
      width: 60px;
      height: 60px;
    }
  }
`;
const Img = styled.img`
  margin: 15px 0;
  max-width: 100%;
`;

const Monero = () => {
  return (
    <Fragment>
      <Header>
        <Title>
          <LogoMoneroTitle />
          <Glitch>Monero Project</Glitch>
        </Title>
        <Code>
          <p className="comment">
            Monero is the leading cryptocurrency with a focus on private and
            censorship-resistant transactions
          </p>
          <p style={{ fontSize: '18px' }}>
            <TypistFixHeight
              cursor={{ hideWhenDone: true }}
              avgTypingDelay={25}
              stdTypingDelay={25}
              startDelay={1000}
            >
              Most existing cryptocurrencies, including Bitcoin and Ethereum,
              have transparent blockchains, meaning that transactions are openly
              verifiable and traceable by anyone in the world. Furthermore,
              sending and receiving addresses for these transactions may
              potentially be linkable to a person's real-world identity. Monero
              uses cryptography to shield sending and receiving addresses, as
              well as transacted amounts.
            </TypistFixHeight>
          </p>
        </Code>

        <PixelImg
          mod="pixel"
          depth="0.1"
          url="/assets/i/events/35_Chaos_Communication_Congress.jpg"
        />
      </Header>
      <Section>
        <Flex className="align-items-center">
          <Half>
            <SubTitle>
              Monero transactions are confidential and untraceable
            </SubTitle>
            <p>
              Every Monero transaction, by default, obfuscates sending and
              receiving addresses as well as transacted amounts. This always-on
              privacy means that every Monero user's activity enhances the
              privacy of all other users, unlike selectively transparent
              cryptocurrencies (e.g. Z-Cash).
            </p>
            <SubTitle>Monero is fungible</SubTitle>
            <p>
              By virtue of obfuscation, Monero cannot become tainted through
              participation in previous transactions. This means Monero will
              always be accepted without the risk of censorship.
            </p>
            <SubTitle>Monero blocksize is scalable</SubTitle>
            <p>
              Monero is also decentralized due to the ASIC-resistant proof of
              work algorithm, therefore the network will never be controlled by
              a small number of entities. Important thing is that the blocksize
              is scalable so Monero will NEVER reach a transaction limit imposed
              by the blocksize.
            </p>
          </Half>
          <Half>
            <iframe
              width="560"
              height="315"
              title="What Is Monero?"
              src="https://www.youtube.com/embed/TZi9xx6aiuY"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </Half>
        </Flex>
      </Section>
      <Section>
        <TitleHeading2>
          <Glitch>Exantech contribution to Monero</Glitch>
        </TitleHeading2>
        <Flex>
          <Half>
            <SubTitle>Exantech Monero remote nodes</SubTitle>
            <Img
              src="/assets/i/projects/node.png"
              srcset="/assets/i/projects/node@2x.png 2x"
              alt="Exantech Monero remote nodes"
            />
            <p>
              The Monero software architecture allows for an easy way to use
              Monero without downloading the entire blockchain. This is called
              «using a remote node».
            </p>
            <p>
              Essentially, your wallet program connects to someone else's monero
              (blockchain) program, a.k.a node, a.k.a. network service. How does
              it work? The open node addresses actually point to many different
              nodes — when you request one, one in the list is selected. So if
              it doesn't work once, just try again.
            </p>
          </Half>
          <Half>
            <SubTitle>To use remote nodes with the Monero CLI:</SubTitle>
            <Pre>
              ./monero-wallet-cli --daemon-address monero.exan.tech:18081
            </Pre>
            <SubTitle>Stagenet remote nodes:</SubTitle>
            <Pre>
              ./monero-wallet-cli --daemon-address
              monero-stagenet.exan.tech:38081 --stagenet
            </Pre>
            <LogoTor />
            <SubTitle>Monero via TOR:</SubTitle>
            <Pre>
              torsocks ./monero-wallet-cli --daemon-address
              monero5sjoz5xmjn.onion:18081
            </Pre>
            <p>or:</p>
            <Pre>torsocks ./monero-wallet-gui</Pre>
            <p>
              While torsocks/torify is available on MacOSX/Linux, Windows users
              can use
            </p>
            <Pre>https://github.com/cpatulea/TorCap2</Pre>
          </Half>
        </Flex>
      </Section>
      <Section>
        <FullWidth>
          <LogoMonero />
        </FullWidth>
        <Flex>
          <Half>
            <SubTitle>Exantech Monero Blockchain Explorer</SubTitle>

            <p>
              no javascript - no cookies - no web analytics trackers - no images
              - open sourced
            </p>

            <A to="https://monero.exan.tech">https://monero.exan.tech</A>
            <SubTitle>Exantech Monero Stagenet Blockchain Explorer</SubTitle>
            <A to="https://monero-stagenet.exan.tech">
              https://monero-stagenet.exan.tech
            </A>
          </Half>
          <Half>
            <SubTitle>
              Exa Wallet — The First Secure & Shared Monero Wallet
            </SubTitle>
            <p>
              Receive, spend and store your Monero with an open-source
              multisignature wallet
            </p>
            <A to="https://wallet.exan.tech">https://wallet.exan.tech</A>
          </Half>
        </Flex>
      </Section>
      <Section>
        <TitleHeading2>
          <Glitch>Monero features</Glitch>
        </TitleHeading2>
        <Features>
          {features.map((feature, index) => (
            <FeaturesItem key={index}>
              <FeaturesImg>{feature.img}</FeaturesImg>
              <div>
                <FeaturesTitle>{feature.title}</FeaturesTitle>
                <FeaturesDesc>{feature.desc}</FeaturesDesc>
              </div>
            </FeaturesItem>
          ))}
        </Features>
      </Section>
    </Fragment>
  );
};

export default Monero;
