import React from 'react';
import styled from 'styled-components';
import Device from 'device';

import Messenger from 'shared/components/Messenger';

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0;

  @media ${Device.tablet} {
    margin: 0;
  }
`;
const Item = styled.div`
  flex: 1;
  text-align: left;
`;
const Num = styled.div`
  font-size: 60px;
  color: ${props => props.theme.color.green};

  @media ${Device.tablet} {
    font-size: 100px;
  }
`;
const Text = styled.div`
  font-size: 18px;

  @media ${Device.tablet} {
    font-size: 22px;
  }
`;

const AboutNumbers = ({ children, data = '', ...props }) => {
  return (
    <Wrap>
      {data.map((item, index) => (
        <Item key={index}>
          <Num>
            <Messenger news={[item.num]} />
          </Num>
          <Text>{item.text}</Text>
        </Item>
      ))}
    </Wrap>
  );
};

export default AboutNumbers;
