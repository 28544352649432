import React from 'react';
import styled from 'styled-components';
import Scroll from 'react-scroll';
import Device from 'device';

import { createHistory } from '@reach/router';

import { ReactComponent as SvgTop } from 'shared/assets/icon-totop.svg';

const scroll = Scroll.animateScroll;
const url = createHistory(window);

const ToTop = styled.div`
  transition: all 0.2s 0.1s;
  transform: rotate(-90deg);
  cursor: pointer;
  padding: 10px;
  margin: 0 0 0 10px;
  opacity: 1;
  visibility: visible;

  @media ${Device.tablet} {
    margin: 0 0 0 30px;
    padding: 10px;
  }

  &.totop {
    transform: rotate(0);
  }
  &.homepage {
    &.ontop {
      opacity: 0;
      visibility: hidden;
      transform: rotate(0) scale(0);
    }
  }
`;
const IconTop = styled(SvgTop)`
  width: 14px;
  height: 14px;
  fill: #fff;

  @media ${Device.tablet} {
    width: 20px;
    height: 25px;
  }
`;

class ScrollToTop extends React.Component {
  state = {
    toTop: false,
    homepage: url.location.pathname === '/',
  };

  handleClick = event => {
    if (this.state.toTop) {
      scroll.scrollToTop();
    } else {
      if (!this.state.homepage) {
        window.location.href = '/';
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    if (window.scrollY > 120) {
      this.setState({ toTop: true });
    } else {
      this.setState({ toTop: false });
    }
  };

  render() {
    function classNames(classes) {
      return Object.entries(classes)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(' ');
    }
    const classes = {
      ontop: !this.state.toTop,
      totop: this.state.toTop,
      homepage: this.state.homepage,
    };
    const elClassNames = classNames(classes);

    return (
      <ToTop onClick={this.handleClick} className={elClassNames}>
        <IconTop />
      </ToTop>
    );
  }
}

export default ScrollToTop;
