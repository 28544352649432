import React from 'react';
import { Helmet } from 'react-helmet';

import Monero from './components/Monero';
import ProjectsContext from 'shared/contexts/ProjectsContext';

const MoneroContainer = ({ navigate, path }) => {
  return (
    <ProjectsContext.Provider>
      <Helmet>
        <title>Monero Project</title>
        <meta name="description" content="Exantech" />
      </Helmet>
      <Monero />
    </ProjectsContext.Provider>
  );
};

export default MoneroContainer;
