import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import ExternalLink from 'shared/components/ExternalLink';

const Link = styled(ExternalLink)`
  color: #fff;
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 0 auto 20px;
`;
const SubTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 10px 0;
  width: 100%;
  font-size: 22px;
  line-height: 24px;

  @media ${Device.tablet} {
    font-size: 30px;
    line-height: 48px;
    margin: 0 0 30px 0;
  }
`;

const MeetupBankex = () => {
  return (
    <Fragment>
      <Section>
        <Img src="/assets/i/events/MeetupBankex/30714076_44053742639.jpg" />
        <p>
          On April 13, we held a meeting with the founders and developers of
          BANKEX Foundation, a non-commercial institution for blockchain
          platform BANKEX. Speakers described how to increase Ethereum network
          throughput to tens of thousands of transactions per second using new
          approaches and Proof-of-Stake protocol and to increase anonymity of
          operations using zero-knowledge proofs (zk-SNARKs).
        </p>
        <p>
          BANKEX Foundation is a part of fintech startup BANKEX. It creates
          technologies for adaptation of traditional financial institutes to
          changes in financial market.
        </p>
      </Section>
      <Section>
        <SubTitle>Speakers at the meeting included:</SubTitle>
        <p>
          Pyotr Korolyov: architect/programmer, founder of BANKEX Foundation. He
          has over 10 years' experience of working at largest IT companies
          (Evernote, Intel, Mail.ru) which includes establishing and managing
          mobile development department at fintech organizations.
        </p>
        <p>
          Projects authored by Pyotr include design of protected browser with
          unique distributed encryption system, mobile applications of large
          banks, security audit. Pyotr has founded several actively developing
          open-source libraries which are used in thousands of applications
          today including github-changelog-generator, ActionSheetPicker,
          GaugeKit.
        </p>
        <Img src="/assets/i/events/MeetupBankex/30715167_44053739305.jpg" />
        <p>
          Alexandr Vlasov: blockchain developer and CTO of BANKEX Foundation. He
          is the founder of feeasy.me fintech startup. Alexandr did research
          activity at DESY and Fermilab laboratories, has over 5 years'
          experience in software development (blockchain, Backend, Mobile
          Frontend on iOS).
        </p>
        <Img src="/assets/i/events/MeetupBankex/30716578_44053729305.jpg" />
        <p>
          More photos in{' '}
          <Link to="https://www.facebook.com/exantech/posts/440537303057267">
            Exantech facebook group.
          </Link>
        </p>
      </Section>
    </Fragment>
  );
};

export default MeetupBankex;
