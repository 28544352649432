import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Device from 'device';
import ReactHtmlParser from 'react-html-parser';

import Code from 'shared/components/Code';
import Section from 'shared/components/Section';
import PixelImg from 'shared/components/PixelImg';
import ExternalLink from 'shared/components/ExternalLink';
import Glitch from 'shared/components/Glitch';
import TypistFixHeight from 'shared/components/TypistFixHeight';

import { ReactComponent as SvgLogoTelegram } from 'shared/assets/logo-telegram.svg';

import { ReactComponent as SvgLogoExante } from 'shared/assets/logo-exante.svg';
import { ReactComponent as SvgLogoAmoveo } from 'shared/assets/logo-amoveo.svg';
import { ReactComponent as SvgLogoStickers } from 'shared/assets/icon-market-stickers.svg';

const SvgLogoCss = css`
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 0 15px 0 0;

  @media ${Device.tablet} {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin: 0 25px 0 0;
  }
`;
const LogoExante = styled(SvgLogoExante)`
  ${SvgLogoCss}
`;
const LogoAmoveo = styled(SvgLogoAmoveo)`
  ${SvgLogoCss}
`;
const LogoStickers = styled(SvgLogoStickers)`
  ${SvgLogoCss}
  fill: #fff;
`;
const LogoTelegram = styled(SvgLogoTelegram)`
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;

  @media ${Device.tablet} {
    width: 62px;
    height: 62px;
    margin: 0 15px 0 0;
  }
`;

const Header = styled.header`
  padding: 0 0 30px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 0 0 60px 0;
  }

  .pixel {
    position: absolute;
    top: -100px;
    left: 30%;
    opacity: 0.4;
    max-height: 400px;
    z-index: -1;

    @media ${Device.tablet} {
      top: -200px;
      left: 50%;
      max-height: 800px;
    }
  }
  .dots {
    position: absolute;
    top: 50%;
    fill: #fff;
    right: 0;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    opacity: 0.6;
    transition: all 0.4s;

    @media ${Device.tablet} {
      right: -300px;
      width: 250px;
      height: 250px;
      margin-top: -125px;
    }
  }
`;
const Title = styled.h1`
  margin: 0 0 20px 0;
  font-size: 28px;
  line-height: 40px;
  font-weight: normal;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 62px;
    line-height: 62px;
  }
`;
const TitleHeading2 = styled.h2`
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: normal;
  width: 100%;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 58px;
  }
`;
const BotsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &.align-items-center {
    align-items: center;
  }
`;
const Bot = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media ${Device.tablet} {
    padding: 40px 20px;
  }
`;
const BotName = styled(ExternalLink)`
  font-size: 18px;
  margin: 5px 0 15px 0;
  display: block;
  color: #fff;

  &:hover {
    text-decoration: none;
  }

  @media ${Device.tablet} {
    font-size: 24px;
    margin: 20px 0 25px 0;
  }
`;
const BotDesc = styled.div`
  line-height: 140%;
  font-size: 14px;

  @media ${Device.tablet} {
    font-size: 16px;
  }
  a {
    color: inherit;
  }
`;

const bots = [
  {
    name: 'EXANTE Markets bot',
    desc:
      'This bot provides access to quotes, charts and price alerts for 50,000+ financial instruments in 50+ markets. The bot works for both Group and Private chats. The data feed is delivered by <a target="_blank" href="https://exante.eu">EXANTE</a>',
    img: <LogoExante />,
    link: 'https://t.me/EXANTE_bot',
  },
  {
    name: 'Amoveo Exchange bot',
    desc:
      'This bot allows you to exchange VEO for BTC/ETH and vice versa. Amoveo (VEO) is a highly scalable proof-of-work blockchain aimed for prediction markets, investment and insurance contracts, and various derivatives. Find more info on Amoveo at <a target="_blank" href="https://amoveo.io/">amoveo.io</a>.',
    img: <LogoAmoveo />,
    link: 'https://t.me/ExchangeAmoveo_bot',
  },
  {
    name: 'Markets Stickers',
    desc:
      'This set contains stickers with prices of many financial instruments and cryptocurrencies. Stickers update in near real-time, so when you send a sticker you see the current asset price.',
    img: <LogoStickers />,
    link: 'https://t.me/addstickers/Markets_by_EXANTE_Stickers_bot',
  },
];

const Bots = () => {
  return (
    <Fragment>
      <Header>
        <Title>
          <LogoTelegram />
          <Glitch delay="2">Telegram Bots</Glitch>
        </Title>
        <Code>
          <div className="comment">
            <TypistFixHeight
              cursor={{ hideWhenDone: true }}
              avgTypingDelay={25}
              stdTypingDelay={25}
              startDelay={1000}
            >
              Telegram is a messaging app with a focus on speed and security.
              Since 2015 Telegram supports bots, which are like small programs
              that run right inside Telegram. They are made by third-party
              developers using the <strong>Telegram Bot API.</strong>
            </TypistFixHeight>
          </div>
        </Code>
        <PixelImg
          mod="pixel"
          depth="0.1"
          url="/assets/i/projects/telegram.jpg"
        />
      </Header>
      <Section>
        <TitleHeading2>Meet our Telegram bots!</TitleHeading2>
        <BotsWrap>
          {bots.map((bot, index) => (
            <Bot key={index}>
              {bot.img}
              <div>
                <BotName to={bot.link}>{bot.name}</BotName>
                <BotDesc>{ReactHtmlParser(bot.desc)}</BotDesc>
              </div>
            </Bot>
          ))}
        </BotsWrap>
      </Section>
    </Fragment>
  );
};

export default Bots;
