import React from 'react';
import styled from 'styled-components';
import Device from 'device';

const CodeWrap = styled.div`
  position: relative;
  padding: 0 0 30px 20px;
  margin: 0 0 5px 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${Device.tablet} {
    padding: 0 0 60px 40px;
    margin: 0 0 10px 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 30px;
    width: 1px;
    background: ${props => props.theme.color.line};

    @media ${Device.tablet} {
      bottom: 40px;
    }
  }
  &:after {
    content: '}';
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    font-size: 14px;

    @media ${Device.tablet} {
      font-size: 20px;
    }
  }
  .comment {
    position: relative;
    line-height: 30px;
    font-size: 18px;
    color: #fff;

    @media ${Device.tablet} {
      font-size: 28px;
      line-height: 40px;
    }
    &:before {
      content: '//';
      margin: 0 10px 0 0;
      opacity: 0.3;
    }
  }
  p {
    margin: 0 0 20px 0;
    line-height: 140%;

    &.func {
      color: ${props => props.theme.color.green};
      position: relative;
      margin: 20px 0 20px 0;

      &:after {
        content: '{';
        margin: 0 0 0 10px;
      }
    }
    &.color {
      color: ${props => props.theme.color.green};
    }
  }
`;

const Code = ({ children, cls = '', ...props }) => {
  return (
    <CodeWrap className={cls} $ {...props}>
      {children}
    </CodeWrap>
  );
};

export default Code;
