import Device from 'device';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import Code from 'shared/components/Code';
import ExternalLink from 'shared/components/ExternalLink';
import Glitch from 'shared/components/Glitch';
import PixelImg from 'shared/components/PixelImg';
import Section from 'shared/components/Section';
import TypistFixHeight from 'shared/components/TypistFixHeight';

const Header = styled.header`
  padding: 0 0 30px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 0 0 60px 0;
  }

  .pixel {
    position: absolute;
    top: -100px;
    left: 30%;
    opacity: 0.4;
    z-index: -1;
    max-width: 400px;

    @media ${Device.tablet} {
      top: -200px;
      left: 50%;
      max-width: none;
    }
  }
  .dots {
    position: absolute;
    top: 50%;
    fill: #fff;
    right: 0;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    opacity: 0.6;
    transition: all 0.4s;

    @media ${Device.tablet} {
      right: -300px;
      width: 250px;
      height: 250px;
      margin-top: -125px;
    }
  }
`;
const Title = styled.h1`
  margin: 0 0 20px 0;
  font-size: 28px;
  line-height: 40px;
  font-weight: normal;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 62px;
    line-height: 62px;
  }
`;
const PublicWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &.align-items-center {
    align-items: center;
  }
`;
const Public = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);

  @media ${Device.tablet} {
    padding: 20px 0;
  }
`;
const PublicName = styled(ExternalLink)`
  font-size: 16px;
  margin: 0;
  display: block;
  color: #fff;

  @media ${Device.tablet} {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const publications = [/*
  {
    title: 'PR: Wallet API: multisig_tx_set passing bug fixed',
    link: 'https://github.com/monero-project/monero/pull/5270',
  }, */
  {
    title: 'How do they do it? Crypto anonymization technologies overview',
    link:
      'https://medium.com/@exantech/how-do-they-do-it-crypto-anonymization-technologies-overview-46293108f1d8',
  }, /*
  {
    title: 'PR: disable asm w/ x64 instructions set on i386',
    link: 'https://github.com/monero-project/monero/pull/5210',
  },
  {
    title: 'PR: M/N multisig transactions signature',
    link: 'https://github.com/monero-project/monero/pull/4845',
  }, */
  {
    title:
      'Monero: Confidential Transactions, or Send I Know Not What to Someone I Know Not Whither',
    link:
      'https://medium.com/@exantech/monero-confidential-transactions-or-send-i-know-not-what-to-someone-i-know-not-whither-337f20f0d64e',
  },/*
  {
    title: 'PR: M/N multisig support',
    link: 'https://github.com/monero-project/monero/pull/4036',
  }, */
  {
    title: 'Monero multisignatures explained',
    link:
      'https://medium.com/@exantech/monero-multisignatures-explained-46b247b098a7',
  },/*
  {
    title:
      'PR: import_multisig_info: fix sanity check crash in detach_blockchain',
    link: 'https://github.com/monero-project/monero/pull/3684',
  },
  {
    title: 'PR: WalletApi: publicMultisigSignerKey method',
    link: 'https://github.com/monero-project/monero/pull/3599',
  },
  {
    title:
      'PR: `signMultisigParticipant` & `verifyWithPublicKey` functions added into wallet api',
    link: 'https://github.com/monero-project/monero/pull/3598',
  },
  {
    title: 'PR: multisig entries for wallet api',
    link: 'https://github.com/monero-project/monero/pull/3489',
  },
  {
    title: 'PR: libwallet_merged compilation on android armv7-eabi',
    link: 'https://github.com/monero-project/monero/pull/3452',
  },*/
];

const Publications = () => {
  return (
    <Fragment>
      <Header>
        <Title>
          <Glitch delay="2">Research & development</Glitch>
        </Title>
        <Code>
          <div className="comment">
            <TypistFixHeight
              cursor={{ hideWhenDone: true }}
              avgTypingDelay={25}
              stdTypingDelay={25}
              startDelay={1000}
            >
              We conduct research, contribute to open source projects and
              publish papers in several areas, specifically, cryptography and online security.
            </TypistFixHeight>
          </div>
        </Code>
        <PixelImg
          mod="pixel"
          depth="0.1"
          url="/assets/i/projects/telegram.jpg"
        />
      </Header>
      <Section>
        <PublicWrap>
          {publications.map((p, i) => (
            <Public key={i}>
              <PublicName to={p.link}>{p.title}</PublicName>
            </Public>
          ))}
        </PublicWrap>
      </Section>
    </Fragment>
  );
};

export default Publications;
