import React, { Component } from 'react';
import styled from 'styled-components';
import Device from 'device';

const Toasty = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(100%);
  transition: all 0.4s;
  visibility: hidden;
  }
  &.show {
    visibility: visible;
    transform: translateX(0);
  }
`;
const ToastyImg = styled.img`
  width: 150px;
  @media ${Device.tablet} {
    width: 200px;
  }
`;

export default class Egg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      text: 'exante',
      show: false,
      play: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  showEgg() {
    this.setState({
      show: true,
    });
    let sound = document.getElementById('toastysound');

    sound.addEventListener('canplaythrough', event => {
      console.log('Toasty!');
      sound.play();
      setTimeout(
        function() {
          this.setState({ play: true });
        }.bind(this),
        300,
      );
      setTimeout(
        function() {
          this.setState({ play: false });
        }.bind(this),
        1000,
      );
    });
    sound.addEventListener('ended', event => {
      this.setState({ play: false, show: false });
    });
  }

  handleEnter(k) {
    let str = '' + this.state.message;
    if (str.length >= this.state.text.length) {
      str = str.substring(1);
    }
    this.setState({
      message: str + k,
    });
    if (this.state.message === this.state.text) {
      this.showEgg();
    } else {
      this.setState({
        show: false,
      });
    }
  }
  handleKeyPress(event) {
    const charList = 'abcdefghijklmnopqrstuvwxyz';
    const k = event.key;
    if (charList.indexOf(k) === -1) return;
    if (event.key) {
      this.handleEnter(k);
    }
  }

  render() {
    return this.state.show ? (
      <Toasty className={this.state.play ? 'show' : null}>
        <audio
          id="toastysound"
          src="/assets/toasty.mp3"
          autoPlay={false}
        ></audio>
        <ToastyImg src="/assets/i/Egg.png" alt="Toasty!" />
      </Toasty>
    ) : null;
  }
}
