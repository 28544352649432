import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import Map from 'shared/components/Map';

import { ReactComponent as SvgDotsBig } from 'shared/assets/icon-dots-big.svg';

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  height: auto;

  @media ${Device.tablet} {
    margin: 0 auto 20px;
  }
`;
const SubTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 10px 0;
  width: 100%;
  font-size: 22px;
  line-height: 24px;

  @media ${Device.tablet} {
    font-size: 30px;
    line-height: 48px;
    margin: 0 0 30px 0;
  }
`;
const Half = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 48%;
  }
`;
const DotsBig = styled(SvgDotsBig)`
  width: 120px;
  height: 120px;
  pointer-events: none;
  color: ${props => props.theme.color.green};
  fill: ${props => props.theme.color.green};

  @media ${Device.tablet} {
    width: 250px;
    height: 250px;
  }
`;
const BorderBox = styled.div`
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  border: 2px solid ${props => props.theme.color.green};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: relative;

  @media ${Device.tablet} {
    padding: 50px;
    font-size: 30px;
    line-height: 48px;
    margin: 0;
  }

  .dots {
    position: absolute;
    top: -30px;
    right: 0;

    @media ${Device.tablet} {
      top: -130px;
      right: -30px;
    }
  }
`;
const MapSection = styled.section`
  position: relative;
  overflow: visible;
  margin: 0;
  z-index: 3;

  .map {
    position: relative;

    @media ${Device.tablet} {
      left: -200px;
      min-width: 2000px;
    }
  }
`;

const Barcelona = () => {
  return (
    <Fragment>
      <Section>
        <Half>
          <SubTitle>What is it about?</SubTitle>
          <p>
            While stablecoins were the initial focus of the event, the BTC
            conference also paid special attention to prediction markets
            technology.
          </p>
          <p>
            Denis Voskvitsov, the Chief Executive Officer of Exantech, a company
            that developed storage for VEO coins called MyVeoWallet and
            Telegram-based VEO exchange bot, shared with the BTC attendees his
            vision of how blockchain-based prediction markets may impact our
            future and how this technology is changing the modern world today.
          </p>
        </Half>
        <Half>
          <Img src="/assets/i/events/barcelona-trading-conference@2x.jpg" />
        </Half>
      </Section>
      <Section>
        <p>
          Voskvitsov pointed out four major roadblocks along the way that
          interfere with the adoption of prediction markets:
        </p>
      </Section>
      <Section>
        <Half>
          <p>
            Voskvitsov pointed out four major roadblocks along the way that
            interfere with the adoption of prediction markets:
          </p>
          <ul>
            <li>
              <p>lack of trust;</p>
            </li>
            <li>
              <p>lack of transparency;</p>
            </li>
            <li>
              <p>interferences by providers & limitations;</p>
            </li>
          </ul>
        </Half>
        <Half>
          <BorderBox>
            <DotsBig className="dots" />
            And here’s where, according to Voskvitsov, blockchain technology can
            fundamentally change the way things used to work.
          </BorderBox>
        </Half>
      </Section>
      <Section>
        <Img src="/assets/i/events/1_yfQm84zgqQbkkII6fLiKWg.jpeg" />
        <p>
          Since blockchain technology implies no central server use, there’s no
          [even in theory] possibility of compromising information, which is
          good news for sure.
        </p>
        <p>
          Additionally, blockchain significantly reduces administration costs,
          which makes prediction market services more efficient and affordable.
        </p>
        <p>
          The decentralized network offers a high level of security and
          anonymity. It can’t be manipulated or changed, which excludes the
          possibility of censorship.
        </p>
        <Img src="/assets/i/events/1_5OJli0oxcNzNzz2bMAXpVg.jpeg" />
        <p>
          Special attention was paid to Amoveo as well. While there are already
          a number of prediction market blockchain projects with their own
          communities, Amoveo stands out not just as a product, additionally
          built on the Ethereum or Bitcoin blockchain, but as independent, build
          from scratch blockchain infrastructure, which had no ICO or
          crowdfunding campaign.
        </p>
        <p>
          As you can see, Amoveo is only getting started, so stay tuned, there’s
          more to come!
        </p>
      </Section>
      <MapSection>
        <Map
          lat={41.409628}
          lng={2.218912}
          title="CCIB BARCELONA - Barcelona International Convention Centre, Barcelona, Spain"
        />
      </MapSection>
    </Fragment>
  );
};

export default Barcelona;
