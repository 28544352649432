import React from 'react';
import Barcelona from 'shared/components/Events/Barcelona';
import AmoveoInMoscow from 'shared/components/Events/AmoveoInMoscow';
import ChaosCommunication from 'shared/components/Events/ChaosCommunication';
import ExantechBusinessCyprus from 'shared/components/Events/ExantechBusinessCyprus';
import MeetupBankex from 'shared/components/Events/MeetupBankex';
import GabrielKurman from 'shared/components/Events/GabrielKurman';
import EMCRPredictionMarkets from 'shared/components/Events/EMCRPredictionMarkets';

export const eventsContent = [
  {
    tab: '2017',
    id: 2,
    items: [
      {
        id: 1,
        alias: '2017-09-28-exantech-meetup-blockchain',
        date: '2017-09-28',
        title:
          'Exantech Meetup — an open discussion on cryptocurrencies, blockchain technology and ICO',
        img: '/assets/i/events/exantech_meetup_blockchain.jpg',
        imgX2: '',
        desc:
          'EXANTE and Moscow Exchange hosted an conference for the leading cryptocurrency market experts in Russia. It was a pilot event of Exantech — our new project dedicated to those who want to learn how to manage their money skilfully. Exantech will regularly organise conferences, seminars and discussions with leading experts studying and developing the cryptocurrency market...',
      },
      {
        id: 2,
        alias: '2017-10-28-exantech-code-jam',
        date: '2017-10-28',
        title: 'Exantech Code Jam: Hacktoberfest',
        img: '/assets/i/events/hacktoberfest.jpg',
        imgX2: '/assets/i/events/hacktoberfest@2x.jpg',
        desc:
          'On the 28th of October in Moscow at Trekhgornaya Manufacktura we launched a small scale hackaton for those willing to complete their projects for Hacktoberfest — an open-source coding and development festival organised by an american cloud computing platform Digital Ocean. According to the annual festival`s rules developer`s teams commit their pool-requests to GitHub in October. For each 4 such requests they get branded T-shirts from the organisers.',
      },
      {
        id: 3,
        alias: '2017-11-30-exantech-business-cyprus',
        date: '2017-11-30',
        title: 'Exantech Business Cyprus',
        img: '/assets/i/events/business-cyprus.jpg',
        imgX2: '/assets/i/events/business-cyprus@2x.jpg',
        desc:
          'People are getting more and more involved into cryptocurrencies and blockchain technologies nowadays. However, only a few of them are aware of the initial purpose of different tokens and of the benefits they can bring to their business.',
      },
    ],
  },
  {
    tab: '2018',
    id: 3,
    items: [
      {
        id: 4,
        alias: '2018-01-31-exantech-rootstock',
        date: '2018-01-31',
        title:
          'Gabriel Kurman and Yuri Gugnin talked about the modernization of blockchain systems at Exantech meetup',
        img:
          '/assets/i/events/GabrielKurman/1fa4f848d07a19e874568f771eef4eb4.jpg',
        imgX2: '',
        content: <GabrielKurman />,
        desc:
          'Exantech held a meetup with Gabriel Kurman in our Moscow office. Gabriel is a co-founder of RSK (Rootstock), the first open-source smart contract platform built on bitcoin. During his speech, Gabriel explained how RSK can speed up financial operations and improve social interaction within them.',
      },
      {
        id: 5,
        alias: '2018-04-13-exantech-meetup-bankex',
        date: '2018-04-13',
        title: 'Exantech meetup with BANKEX Foundation',
        img: '/assets/i/events/meetup-bankex.jpg',
        imgX2: '/assets/i/events/meetup-bankex@2x.jpg',
        content: <MeetupBankex />,
        desc:
          'Founders of BANKEX Foundation tell about zk-SNARK privacy protocols and technology',
      },
      {
        id: 6,
        alias: '2018-05-30-exantech-business-seminar',
        date: '2018-05-30',
        title: 'Exantech Business: seminar for managers',
        img: '/assets/i/events/business-seminar.jpg',
        imgX2: '/assets/i/events/business-seminar@2x.jpg',
        content: <ExantechBusinessCyprus />,
        desc:
          'All you need to know about cryptocurrencies and mining: how to use it in your business and make money. Cryptocurrencies and blockchain are indissoluble part of the present day business Although, only few people really know why they were created, what benefits they give and how they can be used to make money.',
      },
      {
        id: 7,
        alias: '2018-12-28-chaos-communication-congress',
        date: '2018-12-28',
        title: '35 Chaos Communication Congress',
        img: '/assets/i/events/35_Chaos_Communication_Congress.jpg',
        imgX2: '/assets/i/events/35_Chaos_Communication_Congress@2x.jpg',
        content: <ChaosCommunication />,
        desc:
          'Communication Congress in Leipzig and shared our news on Amoveo technologies, prediction markets and Exantech`s contribution to Monero development. Chaos Communication Congress is an annual conference that gathers thousands of programmers and hackers from around the world.',
      },
    ],
  },
  {
    tab: '2019',
    id: 4,
    items: [
      {
        id: 8,
        alias: '2019-05-19-amoveo-meetup-moscow',
        date: '2019-05-19',
        title: 'Amoveo meetup in Moscow',
        img: '/assets/i/events/amoveo-in-moscow/amoveo_in_moscow.jpeg',
        desc:
          "The first Amoveo meetup, set up in collaboration with cryptoecon.ru, took place on the 17th of May. Dare we say it, the event was a smashing success! What was originally planned as a small local meetup became an event with over 70 people in attendance, many of which learned about Amoveo and prediction markets for the first time! More than that, the meetup was live streamed on Forklog, and the view count on the stream's recording is already over 1,000!",
        content: <AmoveoInMoscow />,
      },
      {
        id: 9,
        alias: '2019-07-12-barcelona-trading-conference',
        date: '2019-07-12',
        title:
          'Exantech CEO on Prediction Markets at Barcelona Trading Conference 2019',
        img: '/assets/i/events/barcelona-trading-conference.jpg',
        imgX2: '/assets/i/events/barcelona-trading-conference@2x.jpg',
        desc:
          'Last week, Barcelona gathered a wide number of crypto enthusiasts together in one place called the Barcelona Trading Conference (BTC) to spotlight the most promising blockchain-related trends in the short&long-term perspective.',
        content: <Barcelona />,
      },
      {
        id: 10,
        alias: '2019-09-06-prediction-markets-meetup',
        date: '2019-09-06',
        title: 'Exantech & EMCR Prediction Markets research',
        img: '/assets/i/events/emcr.jpg',
        desc:
          'This September Exantech presented the first academic grade research on blockchain prediction markets in Russia. Research was carried out by students of Russian School of Economy and Kazan State University on EMCR platform. More than 50 people were attracted by the meetup learning the research results.',
        content: <EMCRPredictionMarkets />,
      },
    ],
  },
];
