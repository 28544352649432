import React from 'react';
import styled from 'styled-components';
import Device from 'device';

const ScheduleWrap = styled.div``;
const Item = styled.section`
  display: flex;
  width: 100%;
  padding: 10px 0;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;

  @media ${Device.tablet} {
    padding: 15px 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -100px;
    background: ${props => props.theme.color.green};
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    pointer-events: none;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 28px;
    left: -60px;
    border: 4px solid rgba(255, 255, 255, 0);
    border-top: 4px solid #707070;
    z-index: 1;
    transition: all 0.2s 0.2s;
    transform-origin: center;
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }
    &:after {
      border-top: 4px solid #fff;
      opacity: 0.6;
      transform: rotate(-90deg) translateX(2px);
    }
    div {
      color: #fff;
    }
  }
`;
const Time = styled.div`
  width: 100%;
  max-width: 50px;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 0 0 0;

  @media ${Device.tablet} {
    max-width: 150px;
    font-size: 20px;
    line-height: 32px;
    padding: 0;
  }
`;
const About = styled.div`
  flex: 1;
  width: auto;
  padding: 0 0 0 10px;

  @media ${Device.tablet} {
    padding: 0 0 0 100px;
  }
`;
const Name = styled.div`
  font-size: 20px;
  line-height: 32px;

  @media ${Device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  position: relative;
  margin: 5px 0 0 0;

  @media ${Device.tablet} {
    font-size: 20px;
    line-height: 32px;
  }

  &:before {
    content: '//';
    margin: 0 10px 0 0;
  }
`;

const Schedule = ({ data = '', ...props }) => {
  return (
    <ScheduleWrap>
      {data.map((schedule, index) => (
        <Item key={index}>
          <Time>{schedule.time}</Time>
          <About>
            {schedule.name ? <Name>{schedule.name}</Name> : null}
            {schedule.text ? <Text>{schedule.text}</Text> : null}
          </About>
        </Item>
      ))}
    </ScheduleWrap>
  );
};

export default Schedule;
