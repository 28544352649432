import Device from 'device';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SvgDots } from 'shared/assets/icon-dots.svg';

const team = [
  {
    id: 1,
    name: 'Denis Voskvitsov',
    position: 'CEO',
    img: 'denis-voskvitsov.png',
  },
  {
    id: 5,
    name: 'Dmitriy Zhidkih',
    position: 'CTO',
    img: 'dmitriy-zhidkih.png',
  },
  {
    id: 3,
    name: 'Artur Morozov',
    position: 'Android developer',
    img: 'artur-morozov.png',
  },
  {
    id: 4,
    name: 'Pavel Reshetov',
    position: 'Frontend developer',
    img: 'pavel-reshetov.png',
  },

  {
    id: 7,
    name: 'Sergey Piankov',
    position: 'C++/Go developer',
    img: 'sergey-piankov.png',
  },
  {
    id: 8,
    name: 'Evgeniy Khalimonchik',
    position: 'PM',
    img: 'evgeny-khalimonchik.png',
  },

];
const TeamWrap = styled.div``;
const Person = styled.section`
  display: block;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 25px 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    h3 {
      color: ${props => props.theme.color.green};
    }
    .photo-wrap {
      opacity: 1;
      visibility: visible;
    }
    .photo {
      transform: translateX(0);
    }
    .dots {
      transform: translateX(0);
    }
  }
`;
const Name = styled.h3`
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: #fff;
  transition: all 0.4s;

  @media ${Device.tablet} {
    font-size: 20px;
    line-height: 32px;
    margin: 0 0 5px 0;
  }
`;
const Position = styled.p`
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  color: ${props => props.theme.color.line};

  @media ${Device.tablet} {
    font-size: 20px;
    margin: 0 0 5px 0;
    line-height: 32px;
  }

  &:after {
    content: '/>';
    margin: 0 0 0 10px;
  }
`;
const PhotoWrap = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  height: 150px;
  margin-top: -75px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s;

  @media ${Device.tablet} {
    height: 240px;
    margin-top: -120px;
  }
`;
const Photo = styled.img`
  width: 150px;
  height: 150px;
  transform: translateX(50px);
  transition: all 0.2s;

  @media ${Device.tablet} {
    width: 240px;
    height: 240px;
  }
`;
const Dots = styled(SvgDots)`
  fill: ${props => props.theme.color.green};
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: -50px;
  transition: all 0.2s 0.2s;
  transform: translateX(-50px);
  transform-origin: center;
  transform-box: fill-box;

  @media ${Device.tablet} {
    width: 162px;
    height: 162px;
    margin-top: -81px;
    left: -107px;
  }
`;

class TeamList extends React.Component {
  render() {
    return (
      <TeamWrap>
        {team.map((item, index) => (
          <Person key={index}>
            <Name>{item.name}</Name>
            <Position>{item.position}</Position>
            <PhotoWrap className="photo-wrap">
              <Photo
                className="photo"
                src={item.img ? '/assets/i/team/' + item.img : null}
                alt={item.img ? item.name : null}
              />
              <Dots className="dots" />
            </PhotoWrap>
          </Person>
        ))}
      </TeamWrap>
    );
  }
}

export default TeamList;
