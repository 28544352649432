import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import ExternalLink from 'shared/components/ExternalLink';

const Link = styled(ExternalLink)`
  color: #fff;
`;
const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  height: auto;

  @media ${Device.tablet} {
    margin: 0 auto 20px;
  }
`;

const GabrielKurman = () => {
  return (
    <Fragment>
      <Section>
        <Img
          src="/assets/i/events/GabrielKurman/1fa4f848d07a19e874568f771eef4eb4.jpg"
          alt="Gabriel Kurman"
        />
        <p>
          This week, we held an Exantech meetup with Gabriel Kurman in our
          Moscow office. Gabriel is a co-founder of RSK (Rootstock), the first
          open-source smart contract platform built on bitcoin. During his
          speech, Gabriel explained how RSK can speed up financial operations
          and improve social interaction within them.
        </p>
        <p>
          "There’s a great chance that RSK will be many times secure and cheaper
          than Ethereum," he said.
        </p>
        <Img
          src="/assets/i/events/GabrielKurman/gk1_BTZx72h.jpg"
          alt="Gabriel Kurman"
        />
        <p>Gabriel Kurman</p>
      </Section>
      <Section>
        <p>
          Besides Gabriel Kurman, a well-known Russian cryptocurrency expert
          Yuri Gugnin joined our meetup. Yuri is the founder of Karma project.
          He talked about the development of the cryptocurrency market in South
          Korea.
        </p>

        <Img
          src="/assets/i/events/GabrielKurman/ug_bIrEzsF.jpg"
          alt="Yuri Gugnin"
        />
        <p>Yuri Gugnin</p>
        <p>
          "In Korea, even a housewife can easily buy and sell cryptocurrency
          using just a mobile application and a bank account. When I was there
          it took me only 5 minutes to purchase a car and pay for it with
          Ethereum". The guests also learned about the current situation with
          the regulation and exchanges.
        </p>
        <p>
          Follow Exantech on{' '}
          <Link to="https://www.facebook.com/exantech/">Facebook</Link> not to
          miss out our upcoming meetups!
        </p>
      </Section>
    </Fragment>
  );
};

export default GabrielKurman;
