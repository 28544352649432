import React, { Fragment, useContext } from 'react';
//import { Link } from '@reach/router';
import Device from 'device';
import styled from 'styled-components';

import Code from 'shared/components/Code';
import Glitch from 'shared/components/Glitch';
import Numbers from 'shared/components/Numbers';
import TeamList from 'shared/components/TeamList';
import TypistFixHeight from 'shared/components/TypistFixHeight';

//import AppContext from 'shared/contexts/AppContext';
import HomeContext from 'shared/contexts/HomeContext';
import Publications from '../../Projects/Publications/components/Publications';

const Main = styled.main`
  position: relative;
  padding: 0;

  @media ${Device.tablet} {
    /*padding: 0 0 0 260px;*/
    padding: 0 0 0 60px;
  }

  .numbers {
    top: 70px;
    @media ${Device.tablet} {
      top: 100px;
    }
  }
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 20px 0;

  @media ${Device.tablet} {
    font-size: 30px;
    margin: 0 0 45px 0;
  }

  mark {
    color: ${props => props.theme.color.green};
    background: none;
  }

  @media ${Device.laptopM} {
    font-size: 48px;
    margin: 0 0 40px 0;
  }
`;
const Section = styled.section`
  width: 100%;
  padding: 70px 0 0 0;
  position: relative;
  display: flex;
  margin: 0 0 30px 0;

  @media ${Device.tablet} {
    padding: 140px 0 0 0;
    margin: 0;
    min-height: 750px;
  }
`;
const SectionWrap = styled.div`
  padding: 0 20px;
  position: relative;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${Device.tablet} {
    padding: 0 0 40px 0;
  }
  @media ${Device.laptop} {
    padding: 0 340px 40px 0;
  }
`;
const SectionWrapFull = styled(SectionWrap)`
  padding: 0 20px;

  @media ${Device.tablet} {
    padding: 0 0 40px 0;
  }
`;
const IntroSection = styled(Section)``;
const EventsSection = styled(Section)``;
const ProjectsSection = styled(Section)``;
const PortfolioSection = styled(Section)``;
const ToolsSection = styled(Section)``;
const TeamSection = styled(Section)``;

const EventsWrap = styled.div`
  position: static;
  padding: 20px;
  width: 100%;

  &.absolute {
    @media ${Device.tablet} {
    }
    @media ${Device.laptop} {
      position: absolute;
      top: 125px;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
    }
  }
`;
const EventsSidebar = styled.div`
  @media ${Device.tablet} {
  }
  @media ${Device.laptop} {
    position: fixed;
    top: 125px;
    bottom: 0;
    padding: 0 0 40px 40px;
    transition: transform 0.4s 0s;
    z-index: 2;
    transform: translateX(
      calc(${props => props.theme.width.container} - 700px)
    );
    background: ${props => props.theme.color.dark};
  }
  @media ${Device.laptop} {
    transform: translateX(
      calc(${props => props.theme.width.container} - 770px)
    );
  }
  @media ${Device.laptopM} {
    padding: 0 0 40px 60px;
    transform: translateX(
      calc(${props => props.theme.width.container} - 580px)
    );
  }

  &.show {
    padding: 0 0 40px 0;
    @media ${Device.tablet} {
      transform: translateX(0);
      position: static;
    }

    &:before {
      opacity: 0;
    }
  }
  &.hide {
    @media ${Device.tablet} {
      transform: translateX(
        calc(${props => props.theme.width.container} - 260px)
      );
      position: static;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    &:before {
      opacity: 0;
    }
  }

  &:before {
    @media ${Device.tablet} {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background: ${props => props.theme.color.line};
      transition: all 0.2s;
    }
  }
`;
const EventsBody = styled.div`
  padding: 0;
`;

const Home = () => {
  const { activeSection } = useContext(HomeContext);

  return (
    <Fragment>
      <Main className="main">
        <Numbers />
        <IntroSection
          id="intro"
          className={activeSection === 'intro' ? 'active' : ''}
        >
          <SectionWrap>
            <Title>
              <Glitch delay="3">#BUILD the future</Glitch>
            </Title>
            <Code>
              <div className="comment">
                <TypistFixHeight
                  cursor={{ hideWhenDone: true }}
                  avgTypingDelay={25}
                  stdTypingDelay={25}
                  startDelay={1500}
                >
                  We work on software products and conduct research in the
                  spheres of cryptography, UX and communications. <br />
                </TypistFixHeight>
              </div>
              <Code>
                <p className="func">start</p>
                <Code />
              </Code>
            </Code>
          </SectionWrap>
        </IntroSection>
        {/*
        <EventsSection
          id="events"
          className={activeSection === 'events' ? 'active' : ''}
        >
          <EventsWrap
            className={(() => {
              switch (activeSection) {
                case 'intro':
                  return 'absolute';
                default:
                  return '';
              }
            })()}
          >
            <EventsSidebar
              className={(() => {
                switch (activeSection) {
                  case 'intro':
                    return '';
                  case 'events':
                    return 'show';
                  case 'portfolio':
                    return 'hide';
                  case 'projects':
                    return 'hide';
                  case 'tools':
                    return 'hide';
                  case 'team':
                    return 'hide';
                  default:
                    return 'hide';
                }
              })()}
            >
              <EventsBody>
                <Events />
              </EventsBody>
            </EventsSidebar>
          </EventsWrap>
            </EventsSection> */}
        {/*<PortfolioSection
          id="portfolio"
          className={activeSection === 'portfolio' ? 'active' : ''}
        >
          <SectionWrapFull>
            <ProjectsList src="portfolio" />
          </SectionWrapFull>
          </PortfolioSection> */}{/*
        <ProjectsSection
          id="projects"
          className={activeSection === 'projects' ? 'active' : ''}
        >
          <SectionWrapFull>
            <ProjectsList src="projects" />
          </SectionWrapFull>
        </ProjectsSection>
        <ToolsSection
          id="tools"
          className={activeSection === 'tools' ? 'active' : ''}
        >
          <SectionWrapFull>
            <ProjectsList src="tools" />
          </SectionWrapFull>
          </ToolsSection> */}
        <ProjectsSection
          id="projects"
          className={activeSection === 'projects' ? 'active' : ''}
        >
          <SectionWrapFull>
            <Publications />
          </SectionWrapFull>
        </ProjectsSection>
        <TeamSection
          id="team"
          className={activeSection === 'team' ? 'active' : ''}
        >
          <SectionWrapFull>
            <Title>
              <Glitch>Team</Glitch>
            </Title>
            <TeamList />
          </SectionWrapFull>
        </TeamSection>
      </Main>
    </Fragment>
  );
};

export default Home;
