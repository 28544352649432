import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import ExternalLink from 'shared/components/ExternalLink';

const Link = styled(ExternalLink)`
  color: #fff;
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  height: auto;

  @media ${Device.tablet} {
    margin: 0 auto 20px;
  }
`;

const ChaosCommunication = () => {
  return (
    <Fragment>
      <Section>
        <p>
          At the end of last year, we spoke at the Chaos Communication Congress
          in Leipzig and shared our news on Amoveo technologies, prediction
          markets and Exantech's contribution to Monero development. Chaos
          Communication Congress is an annual conference that gathers thousands
          of programmers and hackers from around the world. Technologies,
          digital security, applied cryptography, fundamental science are
          amongst the main topics discussed there.
        </p>
        <p>
          We also took part in the panel Critical Decentralizaion Cluster and
          talked about Amoveo, a blockchain for prediction markets. Amoveo (VEO)
          is a scalable project aimed at investment and insurance contracts, as
          well as various financial derivatives. Another highlight of this year
          was the company's contribution to the development of Monero, in
          particular, the implementation of a generalised multi-signature
          mechanism, the development of wallets with their support under the Exa
          Wallet brand, as well as a new fast synchronisation protocol Monero
          Fastsync.
        </p>
        <p>
          We were pleased to know that other participants of the conference
          followed our Exa Wallet project. In this Exantech's know-how, we used
          an open-source backend component to work with multi-signatures,
          allowing you to use them without losing anonymity. The wallet is
          designed for devices based on Android and iOS, but we plan to release
          a desktop version soon.
        </p>
        <p>
          Many useful and interesting reports were made at the conference. One
          worthy of mentioning is the study of the security of hardware wallets
          of cryptocurrencies. Its authors analysed possible attack vectors and
          demonstrated several options for interfering with the work of popular
          wallets. If you are interested in learning about the possibilities of
          attacks on cryptocurrency storage, please follow{' '}
          <Link to="https://wallet.fail/">this link</Link>.
        </p>
      </Section>
      <Section>
        <Img
          src="/assets/i/events/ChaosCommunication.jpg"
          alt="Denis Voskvitsov"
        />
      </Section>
    </Fragment>
  );
};

export default ChaosCommunication;
