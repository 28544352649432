import { createGlobalStyle, css } from 'styled-components';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function glitch(name, steps) {
  let styles = '';
  let step = steps || 20;
  let n = name || 'name';

  for (let i = 0; i < step + 1; i += 1) {
    let percentage = Math.floor((i / step) * 100);
    styles +=
      `
     ${percentage}% {
       opacity: 1;
       visibility: visible;
       clip-path: polygon(` +
      getRandomInt(100) +
      `%` +
      getRandomInt(100) +
      `%,` +
      getRandomInt(100) +
      `%` +
      getRandomInt(100) +
      `%,` +
      getRandomInt(100) +
      `%` +
      getRandomInt(100) +
      `%, ` +
      getRandomInt(100) +
      `%` +
      getRandomInt(100) +
      `%)
     }`;
  }

  let glitch = '@keyframes glitch-' + n + ' {' + styles + '}';
  return css`
    ${glitch}
  `;
}

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    vertical-align: top;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    display: flex;
    background: #21252b;
    color: #fff;
    font-family: 'Ubuntu Mono', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  input {
    font-family: 'Ubuntu Mono', monospace;
  }
  button {
    font-family: 'Ubuntu Mono', monospace;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  p {
    mark {
      background: none;
      color: ${props => props.theme.color.green};
    }
  }

  #root {
    display: flex;
    width: 100%;
  }
  #root,
  .routerwrap {
    min-height: 100%;
  }
  .routerwrap {
    flex: 1;
  }
  .Typist .Cursor {
  display: inline-block;

  &--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
    @keyframes blink {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
    }
    }
  }

  [data-whatintent='mouse'] *:focus {
    outline: none;
  }

  @keyframes menuicon {
   0%,20%, 40% {
     opacity:0;
   }
   10%, 30%, 50%, 100% {
     opacity:1;
   }
  }
  @keyframes pixelzoom {
   0% {
     transform: scale(1,1);
   }
   30%, 100% {
     transform: scale(1);
   }
  }
  ${glitch(1, 20)};
  ${glitch(2, 20)};
`;

export default GlobalStyles;
