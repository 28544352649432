import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import ExternalLink from 'shared/components/ExternalLink';

const Link = styled(ExternalLink)`
  color: #fff;
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  height: auto;

  @media ${Device.tablet} {
    margin: 0 auto 20px;
  }
`;
const SubTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 10px 0;
  width: 100%;
  font-size: 22px;
  line-height: 24px;

  @media ${Device.tablet} {
    font-size: 30px;
    line-height: 48px;
    margin: 0 0 30px 0;
  }
`;

const EMCRPredictionMarkets = () => {
  return (
    <Fragment>
      <Section>
        <SubTitle>How It Was: Exantech Meetup on Prediction Markets</SubTitle>
        <p>
          Exan.Tech Meetup: Research of Blockchain Prediction Markets. Meetup
          report.
        </p>
        <p>
          In September, fintech company Exantech released the first research on
          blockchain prediction markets. The EMCR platform and students of New
          Economic School (NES) and Kazan Federal University (KFU) were among
          the key contributors. At the meetup, Exantech gathered over 50 people
          eager to e learn about the research results.
        </p>
        <p>
          In the opening, <mark>Alexey Kirienko</mark>,{' '}
          <strong>Сo-Founder of investment company EXANTE</strong>, corroborated
          that decentralized finance, including the prediction markets, has
          become a key trend in finance per se.
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image3.png"
          alt="Alexey Kirienko"
          title="Alexey Kirienko"
        />
        <p>
          “In 2013, I attended one of the first crypto conferences in San Jose,
          California. Back then, it seemed unimaginable that Bitcoin futures
          would be traded on the largest exchange in Chicago, and crypto would
          make the headlines of the most reputable news media. My story is a
          reminder that we are often unable to conceive how far the technology
          can go,” said Alexey.
        </p>
      </Section>

      <Section>
        <p>
          <mark>Denis Voskvitsov</mark>, <strong>Head of Exantech</strong>, a
          tech lab and venture fund established by EXANTE, talked of the people
          behind the technology.
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image2.png"
          alt="Denis Voskvitsov"
          title="Denis Voskvitsov"
        />
        <p>
          “Students of Russian universities are beginning to develop new
          products and implement new ideas. That’s why we work closely with
          future graduates,” commented Denis.
        </p>
      </Section>
      <Section>
        <p>
          <strong>The research encompassed three blocks:</strong>
        </p>
        <SubTitle>
          Development of a Universal Model for Comparing Prediction Markets
        </SubTitle>
        <p>
          <mark>Dinara Vyazmina</mark>, <strong>a student at KFU</strong>, spoke
          about the classification she developed to evaluate prediction markets.
          Dinara noted that many current models are either focused on the tech
          side (e.g. Zack Hess, Amoveo), or biased in favor of their projects
          (e.g. STOX).
        </p>

        <Img
          src="/assets/i/events/preditcion-markets-meetup/image6.png"
          alt="Dinara Vyazmina"
          title="Dinara Vyazmina"
        />
        <p>
          Dinara selected 19 criteria for evaluation and divided them into 3
          blocks: technical, functional-economic and current success. As a
          result of the integral assessment, the Amoveo platform scored the
          highest, followed by Augur, Gnosis and Stox. The Bitcoin Hivemind
          posted the lowest rank in the scoresheet.
        </p>
      </Section>
      <Section>
        <SubTitle>Blockchain-based Prediction Market Oracles</SubTitle>
        <p>
          <mark>Darya Shiryaeva</mark> <strong>from NES</strong> examined
          oracles — one of the major bottlenecks of the blockchain-based
          prediction markets as well as other systems with smart contracts.
          Darya divided the existing oracles into the following types:
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image5.jpg"
          alt="Blockchain-based Prediction Market Oracles"
        />
        <p>
          The main issue with social oracles is corruption. If the stakes are
          high, the source of information could be bribed. PM developers often
          combine oracles of different types, where centralized systems yield
          fast preliminary results that can be further disputed through
          decentralized systems. Darya described in detail the design of oracles
          in five projects: Augur, Amoveo, Gnosis, Seer and Stox.
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image1.png"
          alt="Darya Shiryaeva"
          title="Darya Shiryaeva"
        />
        <p>
          Augur uses a centralized external oracle and a decentralized oracle
          with the betting competition feature powered by reputational tokens
          REP. Amoveo relies on a single decentralized oracle with betting
          competition in a single network currency VEO. The correct answer is
          the one that has accumulated the maximum of reporter’s bets.
        </p>
        <p>
          As the development of the blockchain-based PMs goes on, we’ll be able
          to see more oracle types and combinations and pick the best
          performing.
        </p>
      </Section>
      <Section>
        <SubTitle>The Usage of Prediction Markets in Corporations</SubTitle>
        <p>
          <mark>Anna Titova</mark>, <strong>a student at NES</strong>, gleaned
          the main value of prediction markets for corporations. They not only
          collect information, but also evaluate it. According to various
          sources, PMs are much more precise than industry experts and surveys.
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image7.png"
          alt="Anna Titova"
          title="Anna Titova"
        />
        <p>
          Corporations use PMs for internal estimates: to forecast product
          demand or its quality, collect forecasts from the outside world
          (election results, box offices), and make decisions (e.g., what
          happens to the old product when a new one turns up).
        </p>
        <p>Anna also covered the market mechanisms:</p>
        <ul>
          <li>
            <strong>CDA.</strong> Continuous Double Auction with separate
            securities for each trade. Buyers and sellers submit their bids and
            offers simultaneously based on the auctioneer’s instructions. The
            auctioneer determines a clearing price to fill the orders.
          </li>
          <li>
            <strong>Market maker mechanism.</strong> It enables trading of
            infinitesimal amounts at zero transaction costs and moves prices up
            or down in response to net buying or selling. The market maker can
            be automated or centralized.
          </li>
        </ul>
        <p>
          <strong>Goldman Sachs</strong> and <strong>Deutsche Bank</strong> were
          the pioneers of PM implementation. They developed the largest market
          based on the CDA economic forecasts between 2002 and 2007.
        </p>
        <p>
          Powered by the CDA model, <strong>Siemens</strong> was able to
          forecast termination of an in-house project.{' '}
          <strong>General Electric</strong> employed the model to collected and
          rank employees’ ideas.
        </p>
        <p>
          Back in 2008, <strong>Google</strong> built the largest internal PM
          with 1,500 participants - putting together the two mechanisms — CDA
          and market-making.
        </p>
        <p>
          Russia's largest bank <strong>Sberbank</strong> is also developing its
          own prediction market, as we know from <mark>Andrey Duhovniy</mark>,
          the Executive Director of the Liquidity Risk Modeling Department at
          Sberbank, who spoke at Exantech’s earlier meetup.
        </p>
      </Section>
      <Section>
        <SubTitle>Conclusion</SubTitle>
        <p>
          The research lasted for two months. The team consulted with industry
          experts and gathered information from a variety of sources, including
          reputable news sources, social media and academic journals.
        </p>
        <p>
          The outcome is an in-depth analysis of the current blockchain-based
          prediction markets, now available for English and Russian-speaking
          audiences.
        </p>
        <Img
          src="/assets/i/events/preditcion-markets-meetup/image4.png"
          alt="Anna Titova"
          title="Anna Titova"
        />
        <p>
          We are proud of the students who did a great job and presented it at
          the highest level, even though it was the first such experience for
          many of them. This research wouldn’t have seen the light of day
          without the help of the EMCR platform and its Founder —{' '}
          <mark>Pavel Pikulev</mark>.
        </p>
        <p>
          Several mentors were also involved in the research, including Head of
          Exantech <mark>Denis Voskvitsov</mark>, EXANTE Analyst and a Ph.D. in
          Physics and Mathematics <mark>Viktor Argonov</mark>, Head of XVA/CVA
          Desk at Sberbank CIB <mark>Mihail Turlakov</mark> and Head of PR
          Russia & CIS at EXANTE <mark>Evgenia Konovalova</mark>.
        </p>
        <p>
          Feel free to ask for the full version of the research! We’ll also
          publish it on the{' '}
          <Link to="https://t.me/EMCR_Reports">EMCR Telegram channel</Link> as
          well.
        </p>
        <p>
          P.S. By the way, have you heard that Nasdaq listed the DeFix index?
          The index was launched by EXANTE and included Augur, Gnosis, Amoveo,
          Numerai, Maker and 0x. Follow the quotes of our Decentralised Finance
          index on Nasdaq!
        </p>
        <p>
          <strong>We can’t wait to see you at our next meeting!</strong>
        </p>
      </Section>
    </Fragment>
  );
};

export default EMCRPredictionMarkets;
