import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Device from 'device';

import { createHistory } from '@reach/router';

import ScrollToTop from 'shared/components/Scroll.js';
import ExternalLink from 'shared/components/ExternalLink.js';

import { ReactComponent as SvgClose } from 'shared/assets/icon-close.svg';
import { ReactComponent as SvgLogo } from 'shared/assets/logo-exantech.svg';

import AppContext from 'shared/contexts/AppContext';

const url = createHistory(window);

const Close = styled(SvgClose)`
  width: 40px;
  height: 40px;
  padding: 10px;
  fill: #fff;
  cursor: pointer;

  @media ${Device.tablet} {
    display: none;
  }
`;
const MenuHamburger = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 12px 10px;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media ${Device.tablet} {
    display: none;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 2px;
    background: #fff;
  }
`;

const ToplineWrap = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  z-index: 4;
  background: ${props => props.theme.color.dark};

  @media ${Device.tablet} {
    padding: 0 10px;
  }
  @media ${Device.laptopM} {
    padding: 0;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.width.container};
  margin: 0 auto;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.6);
  align-items: center;
  justify-content: space-between;

  @media ${Device.tablet} {
    padding: 30px 0;
  }
`;
const LogoWrap = styled.div`
  width: 100%;
  max-width: 100px;
  position: relative;

  @media ${Device.tablet} {
    max-width: 260px;
  }

  &:after {
    @media ${Device.tablet} {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100px;
      width: 1px;
      background: rgba(112, 112, 112, 0.6);
    }
  }
`;
const LogoLink = styled.a`
  display: block;
  text-decoration: none;
`;
const IconLogo = styled(SvgLogo)`
  width: 100px;
  height: 30px;

  @media ${Device.tablet} {
    width: 120px;
    height: 40px;
  }
  @media ${Device.laptopM} {
    width: 200px;
    height: 64px;
  }
`;
const Tools = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
const Right = styled.div`
  display: none;

  @media ${Device.tablet} {
    display: flex;
    align-items: center;
  }
`;
const LangList = styled.div`
  display: flex;
`;
const Lang = styled.div`
  display: block;
  padding: 20px;
  cursor: pointer;

  &.current {
    position: relative;
    color: #21252b;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: -50px;
      background: #707070;
      z-index: -1;
    }
  }
`;
const SocialList = styled.div`
  display: flex;
`;
const SocialStyle = css`
  display: block;
  color: #fff;
  text-decoration: underline;
  font-size: 18px;
  text-align: center;

  @media ${Device.tablet} {
    margin: 0 0 0 40px;
  }
  @media ${Device.laptop} {
    margin: 0 0 0 40px;
  }
  @media ${Device.laptopM} {
    font-size: 22px;
  }
`;
const Social = styled.a`
  ${SocialStyle}
`;
const Facebook = styled(ExternalLink)`
  ${SocialStyle}
`;

const Topline = () => {
  const { isOpened, setIsOpened } = useContext(AppContext);
  const handleToggleMenu = () => {
    setIsOpened(isOpened === false ? true : false);
  };

  return (
    <ToplineWrap>
      <Container>
        <LogoWrap>
          {url.location.pathname === '/' ? (
            <IconLogo />
          ) : (
            <LogoLink href="/">
              <IconLogo />
            </LogoLink>
          )}
        </LogoWrap>
        <Tools>
          <ScrollToTop />
          <Right>
            <LangList style={{ display: 'none' }}>
              <Lang>Ru</Lang>
              <Lang className="current">En</Lang>
            </LangList>
            <SocialList>
              <Facebook href="https://www.facebook.com/exantech/?hc_ref=ARQKzIdWb2l0xNFZbZVhSDLR1SDl8upnETVucO5dVft2US1GdzorpYtanMBo9IyRkYM&fref=nf">
                facebook
              </Facebook>
              <Social href="mailto:hello@exan.tech">hello@exan.tech</Social>
            </SocialList>
          </Right>
          {isOpened ? (
            <Close onClick={handleToggleMenu} />
          ) : (
            <MenuHamburger onClick={handleToggleMenu}>
              <span />
              <span />
              <span />
            </MenuHamburger>
          )}
        </Tools>
      </Container>
    </ToplineWrap>
  );
};

export default Topline;
