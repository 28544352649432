import Device from 'device';
import React, { useContext } from 'react';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';

import AppContext from 'shared/contexts/AppContext';
import HomeContext from 'shared/contexts/HomeContext';

import { createHistory } from '@reach/router';

import ExternalLink from 'shared/components/ExternalLink';

import { ReactComponent as SvgMenu } from 'shared/assets/icon-arrow.svg';
import { ReactComponent as SvgDots } from 'shared/assets/icon-dots-big.svg';

const Menu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  z-index: 3;
  padding: 80px 20px 40px;
  background: ${props => props.theme.color.dark};
  border-right: 1px solid ${props => props.theme.color.line};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.4s;
  transform: translateX(-100%);
  opacity: 0;
  left: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  @media ${Device.tablet} {
    top: 125px;
    width: 260px;
    padding: 40px 40px 40px 0;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    left: auto;
  }
`;
const MainNav = styled.nav`
  display: inline-block;
  vertical-align: top;
`;
const MenuLinkStyle = css`
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 10px 0;
  padding: 0 0 0 20px;
  text-decoration: none;

  @media ${Device.tablet} {
    font-size: 20px;
    line-height: 24px;
    padding: 0 0 0 40px;
    margin: 0 0 12px 0;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }

  &.active {
    color: rgba(255, 255, 255, 1);

    svg {
      visibility: visible;
      animation-name: menuicon;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }
  }
`;
const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`;
const MenuLinkA = styled.a`
  ${MenuLinkStyle}
`;
const MenuIcon = styled(SvgMenu)`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -4px;
  fill: ${props => props.theme.color.green};
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;

  @media ${Device.tablet} {
    margin-top: -5px;
  }
`;
const SidebarFooter = styled.footer`
  position: relative;
`;
const Counter = styled.div`
  display: none;

  @media ${Device.tablet} {
    margin: 50px 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
`;
const CounterCurrent = styled.span`
  font-size: 50px;
  line-height: 36px;
  color: ${props => props.theme.color.green};
`;
const CounterTotal = styled.span`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.25);
`;
const Dots = styled(SvgDots)`
  fill: ${props => props.theme.color.line};
  width: 200px;
  height: 180px;
  position: absolute;
  bottom: 50px;
  right: 0;
  z-index: 0;
  pointer-events: none;

  @media ${Device.tablet} {
    width: 290px;
    height: 258px;
    bottom: 100px;
  }
`;
const SocialList = styled.div`
  padding: 20px;
  @media ${Device.tablet} {
    display: none;
  }
`;
const SocialStyle = css`
  display: inline-block;
  color: #fff;
  width: 100%;
  text-decoration: underline;
  font-size: 16px;
  margin: 0 0 15px 0;

  &:last-child {
    margin-bottom: none;
  }
`;
const Social = styled.a`
  ${SocialStyle}
`;
const Facebook = styled(ExternalLink)`
  ${SocialStyle}
`;

const Sidebar = ({ location, handleSet }) => {
  const { activeSection } = useContext(HomeContext);
  const { isOpened } = useContext(AppContext);
  const url = createHistory(window);

  return (
    <Menu className={isOpened ? 'active' : ''}>
      <MainNav>
        {url.location.pathname === '/' ? (
          <MenuLink
            to="intro"
            spy={true}
            smooth={true}
            duration={300}
            onSetActive={handleSet}
            isDynamic={true}
            data-tag="1"
          >
            <MenuIcon />
            intro
          </MenuLink>
        ) : (
          <MenuLinkA href="/#intro">intro</MenuLinkA>
        )}
{/*
        {url.location.pathname === '/' ? (
          <MenuLink
            to="events"
            spy={true}
            smooth={true}
            duration={300}
            offset={0}
            hashSpy={true}
            onSetActive={handleSet}
            isDynamic={true}
          >
            <MenuIcon />
            events
          </MenuLink>
        ) : (
          <MenuLinkA
            href="/#events"
            className={
              url.location.pathname.includes('/event/') ? 'active' : null
            }
          >
            {url.location.pathname.includes('/event/') ? <MenuIcon /> : null}
            events
          </MenuLinkA>
          )} */}
        {/*url.location.pathname === '/' ? (
          <MenuLink
            to="portfolio"
            spy={true}
            smooth={true}
            duration={300}
            hashSpy={true}
            onSetActive={handleSet}
            isDynamic={true}
          >
            <MenuIcon />
            portfolio
          </MenuLink>
        ) : (
          <MenuLinkA
            href="/#portfolio"
            className={
              url.location.pathname.includes('/portfolio/') ? 'active' : null
            }
          >
            <MenuIcon />
            portfolio
          </MenuLinkA>
        )*/}
        {url.location.pathname === '/' ? (
          <MenuLink
            to="projects"
            spy={true}
            smooth={true}
            duration={300}
            hashSpy={true}
            onSetActive={handleSet}
            isDynamic={true}
          >
            <MenuIcon />
            research & development
          </MenuLink>
        ) : (
          <MenuLinkA
            href="/#projects"
            className={
              url.location.pathname.includes('/projects/') ? 'active' : null
            }
          >
            <MenuIcon />
            research & development
          </MenuLinkA>
        )}{/*
        {url.location.pathname === '/' ? (
          <MenuLink
            to="tools"
            spy={true}
            smooth={true}
            duration={300}
            hashSpy={true}
            onSetActive={handleSet}
            isDynamic={true}
          >
            <MenuIcon />
            tools
          </MenuLink>
        ) : (
          <MenuLinkA
            href="/#tools"
            className={
              url.location.pathname.includes('/tools/') ? 'active' : null
            }
          >
            <MenuIcon />
            tools
          </MenuLinkA>
          )} */}
        {url.location.pathname === '/' ? (
          <MenuLink
            to="team"
            spy={true}
            smooth={true}
            duration={300}
            hashSpy={true}
            onSetActive={handleSet}
            isDynamic={true}
          >
            <MenuIcon />
            team
          </MenuLink>
        ) : (
          <MenuLinkA href="/#team">team</MenuLinkA>
        )}
        <SocialList>
          <Facebook href="https://www.facebook.com/exantech/?hc_ref=ARQKzIdWb2l0xNFZbZVhSDLR1SDl8upnETVucO5dVft2US1GdzorpYtanMBo9IyRkYM&fref=nf">
            facebook
          </Facebook>
          <Social href="mailto:hello@exan.tech">hello@exan.tech</Social>
        </SocialList>
      </MainNav>
      <SidebarFooter>
        {/*        <Feedback>
          <FeedbackText>
            Subscribe <br />
            for our news and&nbsp;event updates
          </FeedbackText>
          <FeedbackInput type="email" name="email" placeholder="e-mail" />
          <FeedbackSubmit>
            {' '}
            <Messenger news={['Submit']} />{' '}
          </FeedbackSubmit>
        </Feedback> */}
        {url.location.pathname === '/' ? (
          <Counter>
            <CounterCurrent>
              {(() => {
                switch (activeSection) {
                  case 'intro':
                    return '01';
                  case 'events':
                    return '02';
                  // case 'portfolio':
                  //   return '03';
                  case 'projects':
                    return '02';
                  case 'tools':
                    return '04';
                  case 'team':
                    return '03';
                  default:
                    return '01';
                }
              })()}
            </CounterCurrent>
            <CounterTotal>/ 03</CounterTotal>
          </Counter>
        ) : null}
        <Dots />
      </SidebarFooter>
    </Menu>
  );
};

export default Sidebar;
