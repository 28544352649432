import React from 'react';
import { Helmet } from 'react-helmet';

import Publications from './components/Publications';
import ProjectsContext from 'shared/contexts/ProjectsContext';

const PublicationsContainer = ({ navigate, path }) => {
  return (
    <ProjectsContext.Provider>
      <Helmet>
        <title>Cryptocurrency research & publications</title>
        <meta name="description" content="Exantech" />
      </Helmet>
      <Publications />
    </ProjectsContext.Provider>
  );
};

export default PublicationsContainer;
