import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Device from 'device';

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.5;

  max-width: 400px;
  max-height: 400px;

  @media ${Device.tablet} {
    max-width: 1000px;
    max-height: 1000px;
  }
`;

const width = 1000;
const height = 1000;

const PixelImg = ({ ...props }) => {
  const canvas = useRef(null);

  function updateCanvas(imgsrc) {
    const image = new Image();
    image.src = imgsrc;

    image.onload = () => {
      const ctx = canvas.current.getContext('2d');

      canvas.current.style.cssText =
        'image-rendering: optimizeSpeed;' + // FireFox < 6.0
        'image-rendering: -moz-crisp-edges;' + // FireFox
        'image-rendering: -o-crisp-edges;' + // Opera
        'image-rendering: -webkit-crisp-edges;' + // Chrome
        'image-rendering: crisp-edges;' + // Chrome
        'image-rendering: -webkit-optimize-contrast;' + // Safari
        'image-rendering: pixelated; ' + // Future browsers
        '-ms-interpolation-mode: nearest-neighbor;'; // IE

      canvas.current.width = width;
      canvas.current.height = height;

      const percent = props.depth || 0.03;
      const scaledWidth = width * percent;
      const scaledHeight = height * percent;
      ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);

      ctx.msImageSmoothingEnabled = false;
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;

      var icvs = document.createElement('canvas');
      icvs.width = width;
      icvs.height = height;
      //var ictx = icvs.getContext('2d');

      ctx.drawImage(
        canvas.current,
        0,
        0,
        scaledWidth,
        scaledHeight,
        0,
        0,
        width,
        height,
      );

      // For masking blend mix
      ctx.globalCompositeOperation = 'destination-out';

      // Draw the masking gradient.
      const gradient = ctx.createRadialGradient(
        width / 2,
        height / 2,
        1,
        width / 2,
        height / 2,
        width,
      );
      gradient.addColorStop(0, 'transparent');
      gradient.addColorStop(0.5, 'white');
      gradient.addColorStop(1, 'white');

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);
    };
  }

  useEffect(() => {
    updateCanvas(props.url);
  }, [props.url]);

  return (
    <Canvas
      ref={canvas}
      className={props.mod ? props.mod : null}
      width={width}
      height={height}
    >
      Your browser does not support HTML5 Canvas. Try downloading latest Chrome,
      Firefox, Safari, Opera or Internet explorer!
    </Canvas>
  );
};
export default PixelImg;
