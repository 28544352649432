const Theme = {
  color: {
    dark: '#21252b',
    green: '#009632',
    line: 'rgba(112, 112, 112, 0.6)',
  },
  width: {
    container: '1200px',
  },
};

export default Theme;
