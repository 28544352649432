import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import Device from 'device';

import EventsContext from 'shared/contexts/EventsContext';

import { eventsContent } from 'shared/utils/events';

import Code from 'shared/components/Code';
import Numbers from 'shared/components/Numbers';
import PixelImg from 'shared/components/PixelImg';
import TypistFixHeight from 'shared/components/TypistFixHeight';

import { ReactComponent as SvgDots } from 'shared/assets/icon-dots.svg';
import { ReactComponent as SvgDotsBig } from 'shared/assets/icon-dots-big.svg';

const MainWrap = styled.div`
  padding: 0 0 0 25px;

  @media ${Device.tablet} {
    padding: 0;
  }
`;

const Header = styled.header`
  padding: 0 0 20px 0;
  border-bottom: 1px solid ${props => props.theme.color.line};
  position: relative;

  @media ${Device.tablet} {
    padding: 0 0 60px 0;
  }

  .pixel {
    position: absolute;
    top: -100px;
    left: 30%;
    opacity: 0.4;
    max-height: 400px;
    z-index: -1;

    @media ${Device.tablet} {
      top: -200px;
      max-height: 800px;
    }
  }
  .dots {
    position: absolute;
    top: 30%;
    fill: ${props => props.theme.color.green};
    right: 0;
    width: 150px;
    height: 150px;
    opacity: 0.6;
    transition: all 0.4s;

    @media ${Device.tablet} {
      right: -300px;
      width: 250px;
      height: 250px;
      margin-top: -125px;
      fill: #fff;
    }
  }
`;
const Date = styled.time`
  color: ${props => props.theme.color.green};
  font-size: 14px;
  margin: 0 0 15px 0;
  display: inline-block;

  @media ${Device.tablet} {
    font-size: 30px;
    margin: 0;
  }
`;
const Title = styled.h1`
  margin: 0 0 20px 0;
  font-size: 28px;
  font-weight: normal;
  line-height: 32px;

  @media ${Device.tablet} {
    margin: 0 0 40px 0;
    font-size: 62px;
    line-height: 120%;
  }
`;
const SubTitle = styled.h3`
  font-weight: normal;
  margin: 0 0 15px 0;
  width: 100%;
  font-size: 18px;
  line-height: 22px;

  @media ${Device.tablet} {
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 48px;
  }
`;
const Dots = styled(SvgDots)`
  width: 145px;
  height: 145px;
  pointer-events: none;
  color: ${props => props.theme.color.green};
  fill: ${props => props.theme.color.green};
`;
const DotsBig = styled(SvgDotsBig)`
  width: 250px;
  height: 250px;
  pointer-events: none;
  color: ${props => props.theme.color.green};
  fill: ${props => props.theme.color.green};
`;
const ContactsSection = styled.section`
  position: relative;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
  z-index: 3;
  background: ${props => props.theme.color.dark};

  @media ${Device.tablet} {
    padding: 40px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 200px;
    background: ${props => props.theme.color.dark};
  }

  p {
    font-size: 18px;
    line-height: 30px;
    margin-block-start: 0;
  }
  a {
    color: #fff;
  }
  .dots {
    position: absolute;
    top: -130px;
    right: 50px;
    z-index: 2;
  }
`;
const Left = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 55%;
  }
`;

const EventContainer = ({ navigate, eventId }) => {
  const eventState = {};

  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const reducer = (acc, ev) => acc.concat(ev.items);
    const events = eventsContent.reduce(reducer, []);
    const event = events.find(e => {
      return e.alias === eventId;
    });
    setEventData(event);
  });

  if (!eventData) return null;

  const date = DateTime.fromISO(eventData.date)
    .setLocale('en')
    .toLocaleString(DateTime.DATE_FULL);

  return (
    <EventsContext.Provider value={eventState}>
      <Helmet>
        <title>{eventData.title} — Exantech</title>
        <meta name="description" content="{eventData.desc}" />
      </Helmet>
      <Numbers />
      <MainWrap>
        <Header>
          <Date datetime="{date}">{date}</Date>
          <Title>{eventData.title}</Title>
          <Code>
            <div className="comment">
              {' '}
              <TypistFixHeight
                cursor={{ hideWhenDone: true }}
                avgTypingDelay={25}
                stdTypingDelay={25}
                startDelay={1000}
              >
                {eventData.desc}{' '}
              </TypistFixHeight>
            </div>
          </Code>
          <PixelImg mod="pixel" depth="0.1" url={eventData.img} />
          <DotsBig className="dots" />
        </Header>
        {eventData.content ? eventData.content : null}
      </MainWrap>
      <ContactsSection>
        <Dots className="dots" />
        <SubTitle>Contacts</SubTitle>
        <Left>
          <p>
            Email: <a href="mailto:events@exan.tech">events@exan.tech</a>
          </p>
        </Left>
      </ContactsSection>
    </EventsContext.Provider>
  );
};

export default EventContainer;
