import React from 'react';
import styled from 'styled-components';
import Device from 'device';

const NumbersWrap = styled.div`
  position: absolute;
  top: 0;
  left: -20px;
  color: #707070;
  bottom: 0;
  width: 30px;
  overflow: hidden;
  padding: 0;
  z-index: 1;
  mix-blend-mode: screen;
  pointer-events: none;
  opacity: 0.4;

  @media ${Device.tablet} {
    padding: 40px 5px;
    top: 20px;
    left: 0;
    display: block;
    opacity: 1;
    width: 40px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 400px;
    background-image: linear-gradient(
      rgba(33, 37, 43, 0),
      rgba(33, 37, 43, 1),
      rgba(33, 37, 43, 1)
    );
    pointer-events: none;

    @media ${Device.tablet} {
      height: 600px;
    }
  }
`;

const Number = styled.div`
  display: block;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
  padding: 5px 0;
  text-align: right;
  font-size: 12px;
  user-select: none;

  @media ${Device.tablet} {
    font-size: 16px;
    padding: 10px 0;
  }
`;

class Numbers extends React.Component {
  createNumbers = () => {
    let numbers = [];

    for (let i = 0; i < 1; i++) {
      let num = [];
      for (let n = 0; n < 999; n++) {
        num.push(<Number key={n}>{` ${n + 1}`}</Number>);
      }
      numbers.push(<div key={i}>{num}</div>);
    }
    return numbers;
  };
  render() {
    return (
      <NumbersWrap className="numbers">{this.createNumbers()}</NumbersWrap>
    );
  }
}

export default Numbers;
