import React from 'react';
import styled from 'styled-components';

const Glitch = ({ children, delay = '0', ...props }) => {
  const GlitchText = styled.span`
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: attr(data-glitch);
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: reverse-alternate;
      opacity: 0;
      visibility: hidden;
    }
    &:before {
      animation-name: 'glitch-1';
      text-shadow: -2px 0 red;
      transform: translateX(2px);
      animation-delay: ${delay + 's'};
    }
    &:after {
      animation-name: 'glitch-2';
      text-shadow: -2px 0 blue;
      transform: translateX(-2px);
      animation-delay: ${delay + 's'};
    }
  `;

  return (
    <GlitchText className="glitchfx" data-glitch={children} $ {...props}>
      {children}
    </GlitchText>
  );
};

export default Glitch;
