import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import Device from 'device';

const Main = styled.main`
  margin: 0 auto;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .last {
    color: ${props => props.theme.color.green};
  }
  div {
    white-space: nowrap;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    user-select: none;

    @media ${Device.tablet} {
      font-size: 20px;
    }
  }
`;
const LastRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;
const GoTo = styled.a`
  color: ${props => props.theme.color.green};
  display: inline;
  margin: 0;
  text-decoration: none;
`;
const loadingAnimation = keyframes`
  0% { width: 0; }
  55% { background: #fff; }
  60% { background: #009632; }
  100% {
    width: 100%;
    background: #009632;
  }
`;
const GotoText = styled.span`
  position: relative;
  margin-left: 15px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 2px;
    background: #fff;
    width: 0%;
    transition: width 0.1s;
    animation: ${loadingAnimation} 8s 0s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    z-index: 1;
  }
`;

const NotFound = () => {
  const [text, setText] = useState('');
  let txt = '<span>404</span>';

  useEffect(() => {
    function print404() {
      let i = txt.repeat(20);
      let row = '<div>' + i + '</div>';
      let t = row.repeat(20);

      setText(t);
    }
    print404();

    setTimeout(() => {
      document.location.href = '/';
    }, 8000);
  }, []);

  return (
    <Main>
      <Helmet>
        <title>404 Page not found</title>
        <meta name="description" content="Whoops.. Sorry but page not found." />
      </Helmet>
      <div>
        {ReactHtmlParser(text)}
        <LastRow>
          <span>
            <span>404</span>
            <span>404</span>
            <span>404</span>
            <span className="last">404</span>
          </span>
          <GotoText>
            If 404 then <GoTo href="/">goto index</GoTo>
          </GotoText>
        </LastRow>
      </div>
    </Main>
  );
};

export default NotFound;
