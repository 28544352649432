import React, { Fragment } from 'react';
import styled from 'styled-components';
import Device from 'device';

import Section from 'shared/components/Section';
import ExternalLink from 'shared/components/ExternalLink';
import Map from 'shared/components/Map';

const Link = styled(ExternalLink)`
  color: #fff;
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  height: auto;

  @media ${Device.tablet} {
    margin: 0 auto 20px;
  }
`;
const Half = styled.div`
  width: 100%;

  @media ${Device.tablet} {
    max-width: 48%;
  }
`;

const MapSection = styled.section`
  position: relative;
  overflow: visible;
  margin: 0;
  z-index: 3;

  .map {
    position: relative;

    @media ${Device.tablet} {
      left: -200px;
      min-width: 2000px;
    }
  }
`;

const AmoveoInMoscow = () => {
  return (
    <Fragment>
      <Section>
        <Img src="/assets/i/events/amoveo-in-moscow/Stepan_Gershuni.jpeg" />
        <p>
          <mark>Stepan Gershuni</mark>, CEO of <strong>credentia.me</strong> and
          the founder of
          <strong>Cryptoeconomics Research Group</strong> took to the mic first.
          He delivered a great in-depth breakdown of the concept of Prediction
          Markets, using Augur as an example.
        </p>
      </Section>
      <Section>
        <Img src="/assets/i/events/amoveo-in-moscow/Denis_Voskvitsov.jpeg" />
        <p>
          <mark>Denis Voskvitsov</mark>, chief of <Link to="/">exan.tech</Link>,
          was the second speaker. He went through a comprehensive presentation
          about Amoveo, talking virtually about everything there is to talk
          about. Starting with the project's history and Zack Hess' approach to
          it, Denis explained the idea behind the project, its capabilities,
          architecture, talked about its community. Finally, he talked about the
          work that exan.tech has done with Amoveo, i.e.{' '}
          <Link to="https://myveowallet.com/">MyVeoWallet</Link> and explained
          how the Moscow community is trying to crowdfund the event with a
          Dominant Assurance Contract. You can read more about it in our{' '}
          <Link to="https://medium.com/amoveo/amoveo-moscow-meetup-announcement-and-crowdfunding-2cc470a5973b">
            previous post
          </Link>
          .
        </p>
      </Section>
      <Section>
        <Img src="/assets/i/events/amoveo-in-moscow/Andrey_Duhovniy.jpeg" />
        <p>
          Finally, we had <mark>Andrey Duhovniy</mark>, executive director of
          the liquidity risk modelling department of <strong>Sberbank</strong>.
          He talked about how Russia's biggest bank is trialing a prediction
          market system, albeit a centralized one, to forecast their corporate
          metrics. The main outcome of their current trial is that such system
          can significantly lower the costs of marketing research for virtually
          any given company.
        </p>
        <p>
          Moreover, Andrey shared some stats: forecasting with prediction
          markets is twice as effective as individual forecasts made by
          analysts; and the minimal threshold of active market participants to
          form a reliable forecast stands at 50.
        </p>
        <p>Finally, the event concluded with a panel-like Q&A session.</p>
        <p>
          Read more on{' '}
          <Link to="https://medium.com/amoveo/amoveo-meetup-in-moscow-how-it-happened-5a638cce7c93">
            Medium
          </Link>
        </p>
      </Section>
      <Section>
        <Half>
          <Img src="/assets/i/events/amoveo-in-moscow/amoveo_in_moscow.jpeg" />
        </Half>
        <Half>
          <Img src="/assets/i/events/amoveo-in-moscow/amoveo_in_moscow_qa.jpeg" />
        </Half>
      </Section>

      <MapSection>
        <Map lat={55.734155} lng={37.568337} title="Amoveo meetup in Moscow" />
      </MapSection>
    </Fragment>
  );
};

export default AmoveoInMoscow;
