import React from 'react';
import { Helmet } from 'react-helmet';

import Home from './components/Home';

//import AppContext from 'shared/contexts/AppContext';

const HomeContainer = ({ navigate, path }) => {
  return (
    <div>
      <Helmet>
        <title>Exantech — #BUILD the future</title>
        <meta
          name="description"
          content="We work on software products and conduct research in the spheres of cryptography, UX and communications."
        />
      </Helmet>
      <Home />
    </div>
  );
};

export default HomeContainer;
