import styled from 'styled-components';
import Device from 'device';

const Section = styled.section`
  padding: 30px 0;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${Device.tablet} {
    padding: 20px 0;
    margin: 0 0 40px 0;
    align-items: stretch;
    flex-direction: row;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    margin-block-start: 0;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${Device.tablet} {
      font-size: 18px;
      line-height: 30px;
    }
  }
  img {
    max-width: 100%;
    user-select: none;
    pointer-events: none;
  }
  h4 {
    color: ${props => props.theme.color.green};
    margin: 20px 0 3px 0;
    font-size: 14px;
    line-height: 22px;

    @media ${Device.tablet} {
      margin: 0 0 30px 0;
      font-size: 20px;
      line-height: 32px;
    }
  }
  ul {
    display: inline-block;
    padding: 0;
    margin: 30px 0;

    @media ${Device.tablet} {
      margin: 30px 0;
    }
    li {
      position: relative;
      margin: 0 0 15px 0;
      font-size: 16px;
      line-height: 20px;
      padding: 0 0 0 20px;
      list-style: none;

      @media ${Device.tablet} {
        padding: 0 0 0 30px;
        margin: 0 0 30px 0;
        line-height: 32px;
      }

      &:last-child {
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: ${props => props.theme.color.green};

        @media ${Device.tablet} {
          top: 12px;
        }
      }
    }
  }
`;

export default Section;
